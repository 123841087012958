import React, {useEffect, useState} from 'react';
import Fdes from "../components/Fdes";
import {withPermissions} from "../../../layout/Permissions";
import {PERMISSION_USER} from "../../user/constants/permissions";
import {useParams} from 'react-router-dom';

const FdesPage = ({user, formIsLoading, familyProducts, loadFdesParameters, isEdited, saveFdesForm, saveFdesGoogleForm, loadReference, saveFdesResult, resultFormIsLoading,
    resultFormIsSaved, lastSavedUserFdesId, generateXml, updateCalcul, cleanNameForm, xmlReportIsLoading, pdfReportIsLoading, calculIsLoading, onUpgradeCalcul, getFdesMetadata, metadata, calcul, getCalcul, clearCalcul, settings}) => {
    const [familyProduct, setFamilyProduct] = useState({});

    const params = useParams()

    useEffect(() => {
        if (params && params.familyProductId) {
            const flatten = (routes) =>
                routes.reduce((acc, r) => {
                    if (r.children && r.children.length) {
                        acc = acc.concat(flatten(r.children));
                    }
                    acc.push(r);

                    return acc;
                }, []);
            setFamilyProduct(flatten(familyProducts).find(x => x._id === params.familyProductId))
        }
    }, [params, familyProducts]);

    return (
        <>
            <Fdes
                user={user}
                familyProduct={familyProduct}
                loadFdesParameters={loadFdesParameters}
                isEdited={isEdited}
                cleanNameForm={cleanNameForm}
                saveFdesForm={saveFdesForm}
                saveFdesGoogleForm={saveFdesGoogleForm}
                loadReference={loadReference}
                saveFdesResult={saveFdesResult}
                resultFormIsLoading={resultFormIsLoading}
                resultFormIsSaved={resultFormIsSaved}
                lastSavedUserFdesId={lastSavedUserFdesId}
                generateXml={generateXml}
                updateCalcul={updateCalcul}
                xmlReportIsLoading={xmlReportIsLoading}
                pdfReportIsLoading={pdfReportIsLoading}
                onUpgradeCalcul={onUpgradeCalcul}
                calculIsLoading={calculIsLoading}
                formIsLoading={formIsLoading}
                getFdesMetadata={getFdesMetadata}
                metadata={metadata}
                id={params ? params.calculId : undefined}
                getCalcul={getCalcul}
                calcul={calcul}
                clearCalcul={clearCalcul}
                settings={settings}
            />
        </>
    )

};

export default withPermissions(FdesPage, PERMISSION_USER);
