import React from 'react';
import CollapsableCard from "./CollapsableCard";
import Breadcrumb from "./Breadcrumb";
import { withPermissions } from "../../layout/Permissions";

const About = ({ localConfigurator }) => {

    function createMarkup() {
        return { __html: localConfigurator && localConfigurator.documentation && localConfigurator.documentation.about ? localConfigurator.documentation.about.description : '' };
    }

    return (
        <>
            <Breadcrumb title={localConfigurator.documentation.about.menu?.label || "à propos"}>
            </Breadcrumb>
            <CollapsableCard
                title={localConfigurator && localConfigurator.documentation && localConfigurator.documentation.about ? localConfigurator.documentation.about.title : ''}>
                <div className="row">
                    <div className="col-md-8">
                        <div className="card-text" dangerouslySetInnerHTML={createMarkup()} />
                    </div>
                    {localConfigurator && localConfigurator.documentation && localConfigurator.documentation.about && localConfigurator.documentation.about.image && (
                        <div className="col-md-4">
                            <img className="img-fluid" src={localConfigurator.documentation.about.image}
                                alt={localConfigurator.documentation.about.title} />
                        </div>
                    )}
                </div>
            </CollapsableCard>
        </>


    )

};

export default withPermissions(About);
