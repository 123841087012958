import React from 'react';
import CollapsableCard from "./CollapsableCard";
import Breadcrumb from "./Breadcrumb";
import { withPermissions } from "../../layout/Permissions";

const Methodology = ({ localConfigurator, methodologyDownloadLinks }) => {

    function createMarkup() {
        return { __html: localConfigurator && localConfigurator.documentation && localConfigurator.documentation.methodology ? localConfigurator.documentation.methodology.description : '' };
    }
    return (
        <>
            <Breadcrumb title={localConfigurator.documentation.methodology.menu?.label || "Méthodologie"}>
            </Breadcrumb>
            <CollapsableCard title={localConfigurator && localConfigurator.documentation && localConfigurator.documentation.methodology ? localConfigurator.documentation.methodology.title : ''}>
                <div className="row">
                    <div className="col-md-8">
                        <div className="card-text" dangerouslySetInnerHTML={createMarkup()} />
                    </div>
                    {methodologyDownloadLinks && methodologyDownloadLinks.length > 0 && (
                        <div className="col-md-4">
                            <p className="h5 mb-2">Liste des notes méthodologiques :</p>
                            {methodologyDownloadLinks.map(guideLink =>
                                <p><a href={guideLink.file} target="_blank" rel="noopener noreferrer"><i className="feather icon-file" /> {guideLink.name === 'main' ? 'Guide principal' : guideLink.name}</a></p>
                            )}
                        </div>
                    )}
                </div>
            </CollapsableCard>
        </>
    )

};

export default withPermissions(Methodology);
