import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import {
  createUserCall,
  deleteUserCall, getUserByAccessToken,
  getUsersCall,
  postAuthenticate,
  recoverPasswordCall, registerCall, sendVerificationCall,
  updateUserCall,
  getAggregatedFamilyProductsCall
} from "../APIClient";
import {
  CHECK_LOGIN,
  CHECK_LOGIN_FAILED,
  CHECK_LOGIN_SUCCESS,
  CLOSE_LOGIN, DISCONNECT_USER, LOGIN_ATTEMPT_NOT_VERIFIED, LOGIN_FORM_FAILED, LOGIN_FORM_SEND,
  LOGIN_FORM_SUCCESS, LOGIN_REQUIRED, LOGIN_SAML,
  OPEN_LOGIN, PERMISSION_REFUSED, RESENT_VALIDATION, SAVE_RECOVER_PASSWORD_FORM, SAVE_REGISTER_FORM,
  SAVE_REGISTER_FORM_SUCCESS, SEND_RECOVER_PASSWORD_FORM, SEND_VERIFICATION, TOKEN_EXPIRED
} from "../common/constants/action-types";
import {
  formatLoginPayload,
  formatPatchPasswordUserPayload,
  formatPatchUserPayload,
  formatPostRecoverPasswordPayload, formatPostResetVerificationPayload,
  formatPostSaveRecoverPasswordPayload,
  formatPostUserPayload, formatPostVerificationPayload, formatRegisterPayload,
  formatPatchUserEDECIntegration
} from "../utils/transformer";
import { toast } from 'react-toastify';
import { parseResponse } from "../utils/http";
import {
  GET_USERS_SEND,
  GET_USERS_SUCCESS,
  UPDATE_ACCOUNT_SETTING_FORM,
  UPDATE_ACCOUNT_SETTING_FORM_SUCCESS,
  UPDATE_PASSWORD_FORM,
  UPDATE_USER_FORM,
  USER_FORM_DELETE,
  USER_FORM_SEND,
  UPDATE_USER_EDEC_INTEGRATION,
  UPDATE_USER_EDEC_INTEGRATION_SUCCESS
} from "../modules/user/constants";

import {
  GET_AGGREGATED_FAMILY_PRODUCTS_SUCCESS
} from "../modules/family-product/constants";
import { familyProductSagas } from "../modules/family-product/saga";
import { fdesSagas } from "../modules/fdes/saga";
import { calculsSagas } from "../modules/calcul/saga";
import { configuratorSagas } from "../modules/configurator/saga";
import { manageGroupSagas } from "../modules/manage-group/saga";
import { assistanceSagas } from '../modules/assistance/saga';
import { push } from 'redux-first-history';

function* postLogin(action) {
  const getLocation = (state) => state.router.location;
  const getLocalConfigurator = (state) => state.configurator.localConfigurator;
  const getToken = (state) => state.user.informations.token;
  const getUserId = (state) => state.user.informations._id;

  try {
    const location = yield select(getLocation);
    const data = yield call(postAuthenticate, formatLoginPayload(action.values));

    if (data && data.user) {
      if (data.user.isVerified && !!data.user.isVerified) {
        yield call(parseResponse, data, LOGIN_FORM_SUCCESS);
        yield put({ type: CLOSE_LOGIN });
        // Load AggregatedFamilyProducts after login success
        const userId = yield select(getUserId);
        const token = yield select(getToken);
        const localConfigurator = yield select(getLocalConfigurator);
        const aggregatedData = yield call(getAggregatedFamilyProductsCall, token, userId, localConfigurator?._id);
        yield call(parseResponse, aggregatedData, GET_AGGREGATED_FAMILY_PRODUCTS_SUCCESS);
        if (location?.pathname !== "/") {
          yield put(push('/'));
        }
      } else {
        yield put({ type: LOGIN_ATTEMPT_NOT_VERIFIED, email: action.values.email });
        yield put({ type: LOGIN_FORM_FAILED, error: "Compte non activé" });
      }
    } else {
      yield put({ type: LOGIN_FORM_FAILED, error: data.code === 404 ? "Utilisateur inconnu." : "Mot de passe invalide." });
    }
  } catch (error) {
    yield put(toast.error("Informations invalides"));
    yield put({ type: LOGIN_FORM_FAILED, error })
  }
}

function* checkLogin(action) {

  const getToken = (state) => state.user.informations.token;

  try {
    const token = yield select(getToken);

    let data = yield call(getUserByAccessToken, token);
    yield call(parseResponse, { ...data, token }, CHECK_LOGIN_SUCCESS);

  } catch (error) {
    yield put({ type: CHECK_LOGIN_FAILED, error });
  }
}

function* loginSaml(action) {
  const getLocation = (state) => state.router.location;

  try {
    const location = yield select(getLocation);

    if (action.values.error !== "error") {
      let data = yield call(getUserByAccessToken, action.values.token);
      data = { user: data, accessToken: action.values.token };

      if (data && data.user && data.user.isVerified) {
        yield call(parseResponse, data, LOGIN_FORM_SUCCESS);
        if (location?.pathname !== "/") {
          yield put(push('/'));
        }
      } else {
        yield put({ type: LOGIN_FORM_FAILED, error: "Mot de passe invalide." });
      }
    } else {
      if (action.values.error === "error") {
        toast.error("Nous n'avons pas pu vous authentifier");
        yield put(push('/'));
      }
    }
  } catch (error) {
    yield put(toast.error("Informations invalides"));
    yield put({ type: LOGIN_FORM_FAILED, error });
  }
}


function* getUsers() {
  const getToken = (state) => state.user.informations.token;

  try {
    const token = yield select(getToken);
    const data = yield call(getUsersCall, token);
    yield call(parseResponse, data, GET_USERS_SUCCESS);
  } catch (error) {
    yield put({ type: "GET_USERS_FAILED", error })
  }
}

function* postUser(action) {
  const getToken = (state) => state.user.informations.token;

  try {
    const token = yield select(getToken);
    const data = yield call(createUserCall, token, formatPostUserPayload(action.values));
    yield call(parseResponse, data, "USER_FORM_SEND_SUCCESS");
    yield put(push('/users'));

  } catch (error) {
    yield put({ type: "USER_FORM_SEND_FAILED", error })
  }
}

function* patchUser(action) {
  const getToken = (state) => state.user.informations.token;

  try {
    const token = yield select(getToken);
    const formattedUser = formatPatchUserPayload(action.values);
    const response = yield call(updateUserCall, token, formattedUser, formattedUser.id);
    yield call(parseResponse, response, "UPDATE_USER_FORM_SUCCESS");
    yield put(push('/users'));
  } catch (error) {
    yield put({ type: "UPDATE_USER_FORM_FAILED", error })
  }
}

function* updateAccountSetting(action) {
  const getToken = (state) => state.user.informations.token;

  try {
    const token = yield select(getToken);
    const formattedUser = formatPatchPasswordUserPayload(action.values);

    const data = yield call(updateUserCall, token, formattedUser, formattedUser.id);
    yield call(parseResponse, data, UPDATE_ACCOUNT_SETTING_FORM_SUCCESS);

    yield put(toast.success("Mot de passe modifié"));
  } catch (error) {
  }
}

function* updatePassword(action) {
  const getToken = (state) => state.user.informations.token;

  try {
    const token = yield select(getToken);
    const formattedUser = formatPatchPasswordUserPayload(action.values);

    const data = yield call(updateUserCall, token, formattedUser, formattedUser.id);

    yield call(parseResponse, data, "UPDATE_PASSWORD_FORM_SUCCESS");
    yield put(toast.success("Mot de passe modifié"));
  } catch (error) {
  }
}

function* deleteUser(action) {
  const getToken = (state) => state.user.informations.token;

  try {
    const token = yield select(getToken);

    yield call(deleteUserCall, token, action.userId);
    yield call(parseResponse, action.userId, "USER_FORM_DELETE_SUCCESS");

  } catch (error) {
    yield put({ type: "USER_FORM_DELETE_ERROR", error })
  }
}

function* loginRequired() {
  yield call(toast.error, "Vous devez vous connecter pour accéder à cette page.");
  yield call(push, "/");
  yield put({ type: DISCONNECT_USER });
  yield put({ type: OPEN_LOGIN });
}

function* permissionRefused() {
  yield call(toast.error, "Vous n'avez pas les droits nécessaire pour accéder à cette page.");
}

function* tokenExpired() {
  yield call(parseResponse, "TokenExpiredError", TOKEN_EXPIRED);
  yield call(toast.error, "Votre session a expiré.");
}

function* sendRecoverPasswordForm(action) {
  const getLocalConfigurator = (state) => state.configurator.localConfigurator;

  try {
    const localConfigurator = yield select(getLocalConfigurator);
    const data = yield call(recoverPasswordCall, formatPostRecoverPasswordPayload(action.values, localConfigurator._id), localConfigurator._id);
    yield call(parseResponse, data, "SEND_RECOVER_PASSWORD_SUCCESS");
    yield put({ type: CLOSE_LOGIN });
    yield call(toast.success, "Un lien permettant de réinitialiser votre mot de passe a été envoyé");
    yield put(push('/'));

  } catch (error) {
    yield put({ type: "SEND_RECOVER_PASSWORD_FAILED", error })
  }
}

function* saveRecoverPasswordForm(action) {
  try {
    const data = yield call(recoverPasswordCall, formatPostSaveRecoverPasswordPayload(action.values));
    yield call(parseResponse, data, "SAVE_RECOVER_PASSWORD_SUCCESS");
    yield put({ type: OPEN_LOGIN });
    yield call(toast.success, "Votre mot de passe vient d'être modifié. Vous pouvez vous connecter");
    yield put(push('/'));


  } catch (error) {
    yield put({ type: "SAVE_RECOVER_PASSWORD_FAILED", error })
  }
}

function* saveRegister(action) {
  const getLocalConfigurator = (state) => state.configurator.localConfigurator;
  const getConfigurators = (state) => state.configurator.configurators;

  try {
    const localConfigurator = yield select(getLocalConfigurator);
    const listConfigurators = yield select(getConfigurators);
    const data = yield call(registerCall, formatRegisterPayload(action.values, localConfigurator._id));
    yield call(parseResponse, { ...data, listConfigurators }, SAVE_REGISTER_FORM_SUCCESS);
  } catch (error) {
    yield put({ type: "SAVE_REGISTER_FORM_FAILED", error })
  }
}

function* sendVerification(action) {
  try {
    const data = yield call(sendVerificationCall, formatPostVerificationPayload(action.token));
    yield call(parseResponse, data, "SAVE_VERIFICATION_SUCCESS");

  } catch (error) {
    yield put({ type: "SAVE_VERIFICATION_FAILED", error })
  }
}

function* resentVerification() {
  const getLoginAttempt = (state) => state.user.loginAttempt;
  const getLocalConfigurator = (state) => state.configurator.localConfigurator;
  try {
    const loginAttempt = yield select(getLoginAttempt);
    const localConfigurator = yield select(getLocalConfigurator);

    const data = yield call(sendVerificationCall, formatPostResetVerificationPayload(loginAttempt.email, localConfigurator._id), localConfigurator._id);
    yield call(parseResponse, data, "RESENT_VALIDATION_SUCCESS");

  } catch (error) {
    yield put({ type: "RESENT_VALIDATION_FAILED", error })
  }
}

function* updateUserEDECIntegration(action) {
  const getToken = (state) => state.user.informations.token;

  try {
    const token = yield select(getToken);

    const formattedUser = formatPatchUserEDECIntegration(action.values);

    const data = yield call(updateUserCall, token, formattedUser, formattedUser.id);

    yield call(parseResponse, data, UPDATE_USER_EDEC_INTEGRATION_SUCCESS);
    yield put(toast.success("Mot de passe modifié"));
  } catch (error) {
  }
}

export const allSagas = [
  takeLatest(LOGIN_FORM_SEND, postLogin),
  takeLatest(CHECK_LOGIN, checkLogin),
  takeLatest(LOGIN_SAML, loginSaml),
  takeLatest(GET_USERS_SEND, getUsers),
  takeLatest(USER_FORM_SEND, postUser),
  takeLatest(UPDATE_USER_FORM, patchUser),
  takeLatest(USER_FORM_DELETE, deleteUser),
  takeLatest(UPDATE_ACCOUNT_SETTING_FORM, updateAccountSetting),
  takeLatest(UPDATE_PASSWORD_FORM, updatePassword),
  takeLatest(LOGIN_REQUIRED, loginRequired),
  takeLatest(PERMISSION_REFUSED, permissionRefused),
  takeLatest(TOKEN_EXPIRED, tokenExpired),
  takeLatest(SEND_RECOVER_PASSWORD_FORM, sendRecoverPasswordForm),
  takeLatest(SAVE_RECOVER_PASSWORD_FORM, saveRecoverPasswordForm),
  takeLatest(SAVE_REGISTER_FORM, saveRegister),
  takeLatest(SEND_VERIFICATION, sendVerification),
  takeLatest(RESENT_VALIDATION, resentVerification),
  takeLatest(UPDATE_USER_EDEC_INTEGRATION, updateUserEDECIntegration)
];

export function* rootSaga() {
  yield all([
    ...allSagas,
    ...familyProductSagas,
    ...manageGroupSagas,
    ...fdesSagas,
    ...calculsSagas,
    ...configuratorSagas,
    ...assistanceSagas
  ])
}
