import React, {useEffect, useState} from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import './Fdes.scss';
import FdesInformations from "../containers/FdesInformations";
import FdesFormGroup from "./../containers/FdesFormGroup";
import SyntheticResult from "../containers/SyntheticResult";
import ModuleResult from "../containers/ModuleResult";
import Switch from "react-switch";
import ReactTooltip from "react-tooltip";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Loader from "../../../common/components/Loader";
import {AFFICHAGERSLT, CREATEPDF, DOWNLOADXMLCONFIG, ETAPESRS, GRAPHERESULTATS, INDICATEURM, INDICATEURRS, NOMCALCUL, NOMPROJET, RESULTATSPARETAPES, TABLEAUDETAIL} from '../constants/metadata';
import EDECLogo from "../../../icons/Pictogramme_EDEC_vert.svg"
import {useParams} from 'react-router-dom';

const Fdes = ({
    user,
    familyProduct,
    loadFdesParameters,
    isEdited,
    saveFdesForm,
    saveFdesGoogleForm,
    isSaved,
    loadReference,
    saveFdesResult,
    resultFormIsSaved,
    generateXml,
    lastSavedUserFdesId,
    readOnly,
    updateCalcul,
    cleanNameForm,
    xmlReportIsLoading,
    pdfReportIsLoading,
    calculIsLoading,
    formIsLoading,
    onUpgradeCalcul,
    getFdesMetadata,
    metadata,
    calcul,
    getCalcul,
    clearCalcul,
    sendXmlToEDEC,
    settings
}) => {

    const params = useParams();
    const [isAuto, setIsAuto] = useState('Y');
    const [isGoogleCalculus, setIsGoogleCalculus] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [createPdf, setCreatePdf] = useState({})
    const [downloadXmlConfig, setDownloadXmlConfig] = useState({})
    const [excludedCouples, setExcludedCouples] = useState([])

    useEffect(() => {
        if (params.calculId) {
            getCalcul(params.calculId);
        } else {
            clearCalcul();
        }
        //eslint-disable-next-line
    }, [getCalcul, clearCalcul])

    useEffect(() => {
        if (familyProduct && familyProduct.currentFdes) {
            loadFdesParameters(calcul && calcul.model && calcul.model._id ? calcul.model._id : familyProduct.currentFdes._id, familyProduct.currentFdes.calculus && familyProduct.currentFdes.calculus.googleDrive && familyProduct.currentFdes.calculus.googleDrive.sheetId !== '', calcul);
            loadReference(familyProduct.currentFdes.standard);
        }
        return () => {
            cleanNameForm()
        };
    }, [familyProduct, loadFdesParameters, loadReference, cleanNameForm, calcul]);

    useEffect(() => {
        const isAutoSaved = localStorage.getItem('isAuto');
        if (isAutoSaved) {
            setIsAuto(isAutoSaved);
        }
    }, [setIsAuto]);

    useEffect(() => {
        if (familyProduct && familyProduct.currentFdes && familyProduct.currentFdes.calculus && familyProduct.currentFdes.calculus.googleDrive && familyProduct.currentFdes.calculus.googleDrive.sheetId !== '') {
            setIsGoogleCalculus(true);
            setIsAuto('N');
        }
    }, [familyProduct, setIsGoogleCalculus, setIsAuto]);

    useEffect(() => {
        if (calcul && calcul.model && calcul.lastModel && calcul.lastModel._id !== calcul.model._id) {
            setShowModal(true);
        }
    }, [calcul]);

    useEffect(() => {
        if (familyProduct && familyProduct.currentFdes) {
            getFdesMetadata(calcul && calcul.model && calcul.model._id ? calcul.model._id : familyProduct.currentFdes._id);
        }
        //eslint-disable-next-line
    }, [familyProduct, calcul, getFdesMetadata])

    useEffect(() => {
        metadata && setCreatePdf(metadata.find(e => e.paramId === CREATEPDF))
        metadata && setDownloadXmlConfig(metadata.find(e => e.paramId === DOWNLOADXMLCONFIG))
    }, [metadata])

    useEffect(() => {
        if (settings?.excludedResultValues?.length > 0) {
            const result = settings.excludedResultValues.map(couple => {
                return {indicator: couple.split('_')[0], step: couple.split('_')[1]}
            })
            setExcludedCouples(result)
        } else setExcludedCouples([])
    }, [settings])

    if (!familyProduct || !familyProduct.currentFdes) {
        return '';
    }

    function handleChangeIsAuto () {
        localStorage.setItem('isAuto', isAuto === 'Y' ? 'N' : 'Y');
        isAuto === 'N' && saveFdesForm()
        setIsAuto(isAuto === 'Y' ? 'N' : 'Y');
    }

    function downloadLink (link) {
        window.open(link);
    }

    function handleClickIcon (type) {
        if (calcul && calcul.reports && calcul.reports[type]) {
            downloadLink(calcul.reports[type])
        } else if (lastSavedUserFdesId !== "" && resultFormIsSaved) {
            generateXml(familyProduct._id)
        }
    }

    function handleUpgradeCalcul () {
        if (calcul && calcul._id) {
            onUpgradeCalcul(calcul._id);
        }
        setShowModal(false);
    }

    function findMetadata (paramId) {
        const findData = []
        metadata && metadata.map(data => paramId.find(param => param === data.paramId) && findData.push(data))
        return findData;
    }

    function displayDowloadXmlConfig () {
        if (downloadXmlConfig)
            return downloadXmlConfig.visible
        else
            return true
    }

    return (<div className="row fdes">
        <Loader isActive={calculIsLoading || formIsLoading} />

        {calcul && calcul.lastModel && calcul.productFamily && (
            <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
                <Modal.Body>
                    {calcul && calcul.isCompatible === true ?
                        `Ce calcul a été créé avec une version précédente du configurateur de la famille de produits “${calcul.productFamily.name}”. Par chance cette version précédente et la nouvelle version sont compatibles entre elles ! Ainsi vous pouvez 1/ maintenir ce calcul dans sa version originale, ce qui vous permettra uniquement de visualiser ses résultats, 2/ convertir ce calcul selon la nouvelle version, ce qui vous permettra de l'éditer totalement.`
                        : `Ce calcul a été créé avec une version précédente du configurateur de la famille de produits "${calcul.productFamily.name}". Malheureusement cette version précédente et la nouvelle version sont incompatibles entre elles. Ainsi vous ne pouvez plus éditer ce calcul et vous ne pouvez plus générer de FDES, mais seulement visualiser vos paramètres de calcul et les résultats associés.`
                    }
                </Modal.Body>
                <Modal.Footer>
                    {calcul && calcul.isCompatible === true ? (
                        <>
                            <Button variant="info" onClick={() => setShowModal(false)}>Je conserve la version actuelle</Button>
                            <Button variant="success" onClick={handleUpgradeCalcul}>Je convertis selon la nouvelle version</Button>
                        </>
                    ) : <Button variant="info" onClick={() => setShowModal(false)}>Fermer</Button>}
                </Modal.Footer>
            </Modal>
        )}
        <div className="col col-xl-5">
            <PerfectScrollbar wheelpropagation={"false"}>
                <div className="card sticky-card">
                    <div className="card-header card-head-inverse bg-primary">
                        <h4 className="card-title"><span className="badge badge-pill badge-white-title">1</span> Saisir
                            mes paramètres</h4>
                    </div>
                </div>
                {familyProduct && familyProduct.currentFdes && (
                    <>
                        <FdesInformations fdes={calcul && calcul.model ? calcul.model : familyProduct.currentFdes} calcul={calcul} calculName={calcul?.title || ""} readOnly={readOnly} metadata={findMetadata([NOMCALCUL, NOMPROJET])} />
                        <FdesFormGroup isAuto={isAuto} readOnly={readOnly} />
                    </>
                )}
            </PerfectScrollbar>
        </div>
        <div className="col col-xl-7">
            <PerfectScrollbar wheelpropagation={"false"}>
                <div className="card sticky-card">
                    <div className="card-header card-head-inverse bg-primary">
                        <h4 className="card-title refresh-button-container">
                            <div><span
                                className="badge badge-pill badge-white-title">2</span> Afficher mes résultats
                            </div>
                            {isAuto === 'N' && (
                                <button onClick={(isGoogleCalculus === true) ? saveFdesGoogleForm : saveFdesForm} type="button" disabled={isEdited}
                                    className="btn btn-secondary btn-min-width float-right">Mettre à jour</button>
                            )}
                            {isGoogleCalculus !== true && (
                                <label>
                                    <span className="mr-1">Mise à jour automatique</span>
                                    <Switch onChange={handleChangeIsAuto} checked={isAuto === 'Y'} />
                                </label>
                            )}
                        </h4>

                    </div>
                </div>
                <SyntheticResult metadata={findMetadata([INDICATEURRS, ETAPESRS, AFFICHAGERSLT])} excludedCouples={excludedCouples} />
                <ModuleResult metadata={findMetadata([INDICATEURM, RESULTATSPARETAPES, GRAPHERESULTATS, TABLEAUDETAIL])} />
                <div className="card sticky-card sticky-card-bottom">
                    <div className="d-flex flex-row flex-wrap justify-content-between  card-header card-head-inverse bg-primary ">
                        <h4 className='card-title'><span
                            className="badge badge-pill badge-white-title">3</span> Utiliser mes résultats </h4>
                        <div className='ml-auto'>
                            <span className="toolTip" data-for="updateCalcul"
                                data-tip={(!isEdited) ? "Pour enregistrer vous devez d’abord mettre à jour le calcul" : ''}>
                                <button onClick={() => calcul && calcul._id ? updateCalcul(calcul._id) : lastSavedUserFdesId !== "" ? updateCalcul(lastSavedUserFdesId) : saveFdesResult()}
                                    type="button" disabled={(isAuto === 'N') ? (isEdited) ? isSaved : true : false || readOnly}
                                    className="btn btn-secondary btn-min-width float-right">Enregistrer</button>
                            </span>
                            <ReactTooltip
                                id="updateCalcul"
                                place="right"
                                type="info"
                                className="basic-tooltip"
                            />
                            <span className="toolTip" data-for="generateXml"
                                data-tip={(lastSavedUserFdesId === "" || !resultFormIsSaved) ? createPdf && createPdf.helpText ? createPdf.helpText : 'Pour générer une FDES vous devez d’abord enregistrer' : ''}>
                                <button onClick={() => generateXml(familyProduct._id)}
                                    type="button"
                                    disabled={(readOnly || lastSavedUserFdesId === "" || !resultFormIsSaved)}
                                    className="btn btn-secondary btn-min-width float-right mr-1"
                                    data-form='generateFdes'
                                    data-tip={createPdf && createPdf.helpText ? createPdf.helpText : 'Pour générer une FDES vous devez d’abord enregistrer'}
                                >{createPdf && createPdf.name ? createPdf.name : 'Générer ma FDES'}
                                </button>
                            </span>
                            <ReactTooltip
                                id="generateFdes"
                                place="top"
                                type="info"
                                className="basic-tooltip" />
                            <ReactTooltip
                                id="generateXml"
                                place="top"
                                type="info"
                                className="basic-tooltip"
                            />
                            <ReactTooltip
                                id="generatePdf"
                                place="top"
                                type="info"
                                className="basic-tooltip"
                            />
                            {displayDowloadXmlConfig() && (xmlReportIsLoading ? <div className="spinner-border  mr-1 float-right " role="status" /> :
                                <span
                                    className={`float-right icon-file ${calcul && calcul.reports && calcul.reports.xml ? 'active' : ''}`}
                                    data-for="generateXml"
                                    onClick={() => handleClickIcon('xml')}
                                    data-tip={lastSavedUserFdesId === "" || !resultFormIsSaved ? (createPdf && createPdf.helpText ? createPdf.helpText : 'Pour générer une FDES vous devez d’abord enregistrer') : ''}><i

                                        className="fa fa-file-code-o" /></span>
                            )}
                            {pdfReportIsLoading ? <div className="spinner-border  mr-1 float-right " role="status" /> :
                                <span
                                    className={`float-right icon-file ${calcul && calcul.reports && calcul.reports.pdf ? 'active' : ''}`}
                                    data-for="generateXml"
                                    onClick={() => handleClickIcon('pdf')}
                                    data-tip={lastSavedUserFdesId === "" || !resultFormIsSaved ? (createPdf && createPdf.helpText ? createPdf.helpText : 'Pour générer une FDES vous devez d’abord enregistrer') : ''}><i
                                        className="fa fa-file-pdf-o" /></span>
                            }
                            {calcul && calcul.reports && calcul.reports.xml &&
                                <span
                                    className={"float-right icon-file active"}
                                    onClick={() => sendXmlToEDEC(calcul)}
                                >
                                    {user.integrations && user.integrations.edec && user.integrations.edec.username !== "" && user.integrations.edec.password !== "" && 
                                            <>
                                                <span
                                                    className='mb-1'
                                                    data-tip={'Envoyer XML à EDEC'}
                                                    data-for={`exportXmltoEDEC${calcul._id}`}
                                                    // onClick={() => sendXmlToEDEC(calcul)}
                                                >
                                                    <img src={EDECLogo} alt="EDEC icon" style={{width: 15, marginBottom: 3}}/>
                                                    </span>
                                                <ReactTooltip
                                                    id={`exportXmltoEDEC${calcul._id}`}
                                                    place="left"
                                                    type="info"
                                                    className="basic-tooltip"
                                                />
                                            </>
                                        }
                                    
                                </span>
                            }
                        </div>
                    </div>
                </div>
            </PerfectScrollbar>
        </div>
    </div>)
};

export default Fdes;
