import React from 'react';
import CollapsableCard from "./CollapsableCard";
import Breadcrumb from "./Breadcrumb";
import { withPermissions } from "../../layout/Permissions";

const FDESMere = ({ localConfigurator }) => {

  const elements = localConfigurator && localConfigurator.documentation && localConfigurator.documentation.fdesMeres && localConfigurator.documentation.fdesMeres.elements ?
    [...localConfigurator && localConfigurator.documentation && localConfigurator.documentation.fdesMeres && localConfigurator.documentation.fdesMeres.elements] : [];

  function createMarkup() {
    return { __html: localConfigurator && localConfigurator.documentation && localConfigurator.documentation.fdesMeres ? localConfigurator.documentation.fdesMeres.description : '' };
  }

  return (
    <div className="fdes-mere-container">
      <Breadcrumb title={localConfigurator.documentation?.fdesMeres?.menu?.label || 'FDES Mères'}>
      </Breadcrumb>
      <CollapsableCard
        title={localConfigurator && localConfigurator.documentation && localConfigurator.documentation.fdesMeres ? localConfigurator.documentation.fdesMeres.title : ''}>
        <div className="card-text" dangerouslySetInnerHTML={createMarkup()} />
        <div className="row">
          {elements.sort((a, b) => a.order - b.order).map((element, index) =>
            <figure className="col-md-3 " key={index}
              itemProp="associatedMedia" itemScope=""
              itemType="http://schema.org/ImageObject">
              <a href={element.file} target="_blank" rel="noopener noreferrer">
                <img className="gallery-thumbnail card-img-top"
                  src={element.image} itemProp="thumbnail" alt=""
                />
              </a>
              <div className="card-body px-0">
                <h4 className="card-title">{element.title}</h4>
                <p className="card-text">{element.description}</p>
              </div>
            </figure>
          )}

        </div>
      </CollapsableCard>
    </div>


  )

};

export default withPermissions(FDESMere);
