import {call, put, select, takeLatest, all} from 'redux-saga/effects'
import {
    createFamilyProductCall, createFdesCall,
    deleteFamilyProductCall,
    getFamilyProductsCall, getModelsCall,
    updateFamilyProductCall, updateFdesCall,
    sendCsvToUploader, sendFileToUploader,
    getAggregatedFamilyProductsCall,
    getDefaultProductFamiliesCall
} from "../../../APIClient";
import {
    formatFilePayload,
    formatPatchFdes,
    formatPostFamilyProductPayload,
    formatPostFdes
} from "../../../utils/transformer";
import {toast} from 'react-toastify';
import {parseResponse} from "../../../utils/http";
import {
    GET_AGGREGATED_FAMILY_PRODUCTS,
    GET_AGGREGATED_FAMILY_PRODUCTS_SUCCESS,
    GET_FAMILY_PRODUCTS_SUCCESS,
    LOAD_MODEL_FOR_FAMILY_PRODUCT,
    UPDATE_FAMILY_PRODUCT_FDES,
    GET_DEFAULT_PRODUCT_FAMILIES,
    GET_DEFAULT_PRODUCT_FAMILIES_SUCCESS
} from "../constants";
import {
    DELETE_FAMILY_PRODUCT,
    GET_FAMILY_PRODUCTS, LOAD_MODEL_FOR_FAMILY_PRODUCT_FAILED, LOAD_MODEL_FOR_FAMILY_PRODUCT_SUCCESS,
    SAVE_FAMILY_PRODUCT_FDES, SAVE_FAMILY_PRODUCT_FDES_FAILED, SAVE_FAMILY_PRODUCT_FDES_SUCCESS,
    SAVE_FAMILY_PRODUCT_FORM, UPDATE_FAMILY_PRODUCT_FDES_FAILED, UPDATE_FAMILY_PRODUCT_FDES_SUCCESS,
    UPDATE_FAMILY_PRODUCT_FORM
} from "../constants";
import {disableFamilyProductFormLoader} from "../../../common/actions/loader";
import {push} from 'redux-first-history';

function* getFamilyProducts () {
    const getToken = (state) => state.user.informations.token;

    try {
        const token = yield select(getToken);
        const data = yield call(getFamilyProductsCall, token);
        yield call(parseResponse, data, GET_FAMILY_PRODUCTS_SUCCESS);
    } catch (error) {
        yield put({type: "GET_FAMILY_PRODUCTS_FAILED", error})
    }
}

function* getAggregatedFamilyProducts () {
    const getToken = (state) => state.user.informations.token;
    const getUserId = (state) => state.user.informations._id;
    const getLocalConfigurator = (state) => state.configurator.localConfigurator;

    try {
        const userId = yield select(getUserId);
        const token = yield select(getToken);
        //@TODO load product families after success login.
        if (token === undefined || userId === undefined) {
            console.log('[getAggregatedFamilyProducts] Denied. User not authenticated');
            yield put({type: GET_DEFAULT_PRODUCT_FAMILIES})
            return;
        }
        const localConfigurator = yield select(getLocalConfigurator);
        const data = yield call(getAggregatedFamilyProductsCall, token, userId, localConfigurator?._id);
        yield call(parseResponse, data, GET_AGGREGATED_FAMILY_PRODUCTS_SUCCESS);
    } catch (error) {
        yield put({type: "GET_AGGREGATED_FAMILY_PRODUCTS_FAILED", error})
    }
}

function* getDefaultFamilyProducts () {
    const getLocalConfigurator = (state) => state.configurator.localConfigurator;

    try {
        const localConfigurator = yield select(getLocalConfigurator);
        const data = yield call(getDefaultProductFamiliesCall, localConfigurator?._id);
        yield call(parseResponse, data, GET_DEFAULT_PRODUCT_FAMILIES_SUCCESS);
    } catch (error) {
        yield put({type: "GET_DEFAULT_PRODUCT_FAMILIES_FAILED", error})
    }
}

function* postFamilyProduct (action) {
    const getToken = (state) => state.user.informations.token;

    try {
        const token = yield select(getToken);
        let imageUri = '';
        let guideFile = '';
        let methodologyFile = '';
        let documentationFdesMereElementsFiles = [];

        if (action.values && action.values.methodologyFile && action.values.methodologyFile.name) {
            const image = yield call(sendFileToUploader, token, formatFilePayload(action.values.methodologyFile));
            methodologyFile = image.url;
        }
        if (action.values && action.values.guideFile && action.values.guideFile.name) {
            const image = yield call(sendFileToUploader, token, formatFilePayload(action.values.guideFile));
            guideFile = image.url;
        }
        if (action.values && action.values.file && action.values.file.name) {
            const image = yield call(sendFileToUploader, token, formatFilePayload(action.values.file));
            imageUri = image.url;
        }
        if (action.values && action.values.documentation && action.values.documentation.fdesMeres && action.values.documentation.fdesMeres.elements) {
            documentationFdesMereElementsFiles = yield all(action.values.documentation.fdesMeres.elements.filter(x => x.fileToSend && x.fileToSend.name).map(element => call(sendFileToUploader, token, formatFilePayload(element.fileToSend))));
        }
        const formattedConfigurator = formatPostFamilyProductPayload(action.values, imageUri, guideFile, methodologyFile, documentationFdesMereElementsFiles);
        const data = yield call(createFamilyProductCall, token, formattedConfigurator);
        yield call(parseResponse, data, "SAVE_FAMILY_PRODUCT_FORM_SUCCESS");

        yield put(disableFamilyProductFormLoader());
        yield call(toast.success, "Famille de produit ajoutée");

    } catch (error) {
        yield put({type: "SAVE_FAMILY_PRODUCT_FORM_FAILED", error})
    }
}


function* patchFamilyProduct (action) {
    const getToken = (state) => state.user.informations.token;

    try {
        const token = yield select(getToken);
        let imageUri = '';
        let guideFile = '';
        let methodologyFile = '';
        let documentationFdesMereElementsFiles = '';

        if (action.values && action.values.methodologyFile && action.values.methodologyFile.name) {
            const image = yield call(sendFileToUploader, token, formatFilePayload(action.values.methodologyFile));
            methodologyFile = image.url;
        }
        if (action.values && action.values.guideFile && action.values.guideFile.name) {
            const image = yield call(sendFileToUploader, token, formatFilePayload(action.values.guideFile));
            guideFile = image.url;
        }
        if (action.values && action.values.file && action.values.file.name) {
            const image = yield call(sendFileToUploader, token, formatFilePayload(action.values.file));
            imageUri = image.url;
        }
        if (action.values && action.values.documentation && action.values.documentation.fdesMeres && action.values.documentation.fdesMeres.elements) {
            documentationFdesMereElementsFiles = yield all(action.values.documentation.fdesMeres.elements.filter(x => x.fileToSend && x.fileToSend.name).map(element => call(sendFileToUploader, token, formatFilePayload(element.fileToSend))));
        }

        const formattedConfigurator = formatPostFamilyProductPayload(action.values, imageUri, guideFile, methodologyFile, documentationFdesMereElementsFiles);
        const data = yield call(updateFamilyProductCall, token, formattedConfigurator, formattedConfigurator.id);
        yield call(parseResponse, data, "UPDATE_FAMILY_PRODUCT_FORM_SUCCESS");
        yield put(disableFamilyProductFormLoader());
        yield put(push('/family-product'));

        yield call(toast.success, "Modification enregistrée");



    } catch (error) {
        yield put({type: "UPDATE_FAMILY_PRODUCT_FORM_FAILED", error})
        yield put(disableFamilyProductFormLoader());
    }
}

function* deleteFamilyProduct (action) {
    const getToken = (state) => state.user.informations.token;

    try {
        const token = yield select(getToken);

        yield call(deleteFamilyProductCall, token, action.familyProductId);
        yield call(parseResponse, action.familyProductId, "DELETE_FAMILY_PRODUCT_SUCCESS");
        yield call(toast.success, "Famille de produit supprimée");

    } catch (error) {
        yield put({type: "DELETE_FAMILY_PRODUCT_ERROR", error})
    }
}

function* postModel (action) {
    const getToken = (state) => state.user.informations.token;

    try {
        const token = yield select(getToken);
        let iconUri = '';
        let paramFormUri = '';
        let calculFormUri = '';
        let methodologyNoteUri = '';
        let fdesMereUri = '';
        let guideUri = '';
        if (action.values.icon && action.values.icon.name) {
            const image = yield call(sendFileToUploader, token, formatFilePayload(action.values.icon));
            iconUri = image.url;
        }
        if (action.values.paramForm && action.values.paramForm.name) {
            const image = yield call(sendCsvToUploader, token, formatFilePayload(action.values.paramForm));
            paramFormUri = image.url;
        }
        if (action.values.calculFormUri) {
            calculFormUri = action.values.calculFormUri;
        } else {
            if (action.values.calculForm && action.values.calculForm.name) {
                const image = yield call(sendCsvToUploader, token, formatFilePayload(action.values.calculForm));
                calculFormUri = image.url;
            }
        }
        if (action.values.methodologyNote && action.values.methodologyNote.name) {
            const image = yield call(sendFileToUploader, token, formatFilePayload(action.values.methodologyNote));
            methodologyNoteUri = image.url;
        }
        if (action.values.fdesMereFile && action.values.fdesMereFile.name) {
            const image = yield call(sendFileToUploader, token, formatFilePayload(action.values.fdesMereFile));
            fdesMereUri = image.url;
        }
        if (action.values.guideFile && action.values.guideFile.name) {
            const image = yield call(sendFileToUploader, token, formatFilePayload(action.values.guideFile));
            guideUri = image.url;
        }
        const data = yield call(createFdesCall, token, formatPostFdes(action.values, iconUri, paramFormUri, calculFormUri, methodologyNoteUri, fdesMereUri, guideUri));
        yield call(parseResponse, data, SAVE_FAMILY_PRODUCT_FDES_SUCCESS);
        yield call(toast.success, "Modèle ajouté");
        yield put(push(`/family-product/edit/${action.values.familyProductId}#modele`));

    } catch (error) {
        yield put({type: SAVE_FAMILY_PRODUCT_FDES_FAILED, error});

    }
}

function* patchFdes (action) {
    const getToken = (state) => state.user.informations.token;

    try {
        const token = yield select(getToken);
        let iconUri = '';
        if (action.values.icon && action.values.icon.name) {
            const image = yield call(sendFileToUploader, token, formatFilePayload(action.values.icon));
            iconUri = image.url;
        }
        const data = yield call(updateFdesCall, token, formatPatchFdes(action.values, iconUri), action.values.id);
        yield call(parseResponse, data, UPDATE_FAMILY_PRODUCT_FDES_SUCCESS);
        yield call(toast.success, "Modèle modifié");
        yield put(push(`/family-product/edit/${action.values.familyProductId}#modele`));

    } catch (error) {
        yield put({type: UPDATE_FAMILY_PRODUCT_FDES_FAILED, error})
    }
}

function* getModels (action) {
    const getToken = (state) => state.user.informations.token;

    try {
        const token = yield select(getToken);

        const data = yield call(getModelsCall, token, action.familyProductId);
        yield call(parseResponse, data, LOAD_MODEL_FOR_FAMILY_PRODUCT_SUCCESS);

    } catch (error) {
        yield put({type: LOAD_MODEL_FOR_FAMILY_PRODUCT_FAILED, error})
    }
}

export const familyProductSagas = [
    takeLatest(GET_FAMILY_PRODUCTS, getFamilyProducts),
    takeLatest(GET_AGGREGATED_FAMILY_PRODUCTS, getAggregatedFamilyProducts),
    takeLatest(GET_DEFAULT_PRODUCT_FAMILIES, getDefaultFamilyProducts),
    takeLatest(SAVE_FAMILY_PRODUCT_FORM, postFamilyProduct),
    takeLatest(UPDATE_FAMILY_PRODUCT_FORM, patchFamilyProduct),
    takeLatest(DELETE_FAMILY_PRODUCT, deleteFamilyProduct),
    takeLatest(SAVE_FAMILY_PRODUCT_FDES, postModel),
    takeLatest(UPDATE_FAMILY_PRODUCT_FDES, patchFdes),
    takeLatest(LOAD_MODEL_FOR_FAMILY_PRODUCT, getModels),
];