import React from 'react';
import CollapsableCard from "./CollapsableCard";
import Breadcrumb from "./Breadcrumb";
import Plyr from "plyr-react";
import 'plyr-react/plyr.css'
import './Tutorial.scss';
import { withPermissions } from "../../layout/Permissions";

const Tutorial = ({ localConfigurator, videoTutorialLinks }) => {

    function createMarkup() {
        return { __html: localConfigurator && localConfigurator.documentation && localConfigurator.documentation.videoTutorial ? localConfigurator.documentation.videoTutorial.description : '' };
    }
    return (
        <>
            <Breadcrumb title={localConfigurator.documentation.videoTutorial.menu?.label || "Tutoriels"}>
            </Breadcrumb>
            <CollapsableCard title={localConfigurator && localConfigurator.documentation && localConfigurator.documentation.videoTutorial ? localConfigurator.documentation.videoTutorial.title : ''}>
                <div className="row">
                    <div className="col-xl-8">
                        <div className="card-text" dangerouslySetInnerHTML={createMarkup()} />
                    </div>
                    {localConfigurator && localConfigurator.documentation && localConfigurator.documentation.videoTutorial && (
                        <div className="col-xl-4 mt-lg-0 mt-3">
                            <Plyr source={{
                                type: 'video',
                                sources: [
                                    {
                                        src: localConfigurator.documentation.videoTutorial.video,
                                        provider: 'youtube',
                                    },
                                ],
                            }} />
                        </div>
                    )}
                </div>
                <div className="row mt-2">
                    <div className="col-12">
                        <div className="collapse show card-columns">
                            {videoTutorialLinks.map((guideLink, i) =>
                                <div key={guideLink.video + i} className="card border-grey border-lighten-2">
                                    <div>
                                        <Plyr source={{
                                            type: 'video',
                                            sources: [
                                                {
                                                    src: guideLink.video,
                                                    provider: 'youtube',
                                                },
                                            ],
                                        }} />
                                    </div>
                                    <div className="card-body px-0">
                                        <h4 className="card-title">{guideLink.title}</h4>
                                        <p className="card-text">{guideLink.description}</p>
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>
                </div>
            </CollapsableCard>
        </>


    )

};

export default withPermissions(Tutorial);
