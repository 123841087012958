export const API_URL = process.env.REACT_APP_API_URL;
export const API_CONNECT_ME = API_URL + '/connect/me';
export const API_POST_LOGIN = API_URL + '/connect/authentication';
export const API_GET_CONFIGURATORS = API_URL + '/connect/configurator?$limit=10000';
export const API_GET_LOCAL_CONFIGURATORS = (domain) => `${API_URL}/connect/configurator?domain=${domain}`;
export const API_POST_CONFIGURATORS = API_URL + '/connect/configurator';
export const API_PATCH_CONFIGURATORS = (configuratorId) => `${API_URL}/connect/configurator/${configuratorId}`;
export const API_DELETE_CONFIGURATORS = (configuratorId) => `${API_URL}/connect/configurator/${configuratorId}`;
export const API_FILE_UPLOAD = API_URL + '/connect/upload';
export const API_FILE_UPLOADER = API_URL + '/uploader/file';
export const API_CSV_UPLOADER = API_URL + '/uploader/csv';
export const API_ASSET_UPLOADER = API_URL + '/uploader/upload';
export const API_GET_USERS = API_URL + '/connect/users?$limit=10000';
export const API_POST_USERS = API_URL + '/connect/users';
export const API_PATCH_USERS = (userId) => `${API_URL}/connect/users/${userId}`;
export const API_DELETE_USERS = (userId) => `${API_URL}/connect/users/${userId}`;
export const API_GET_GROUPS = (localConfiguratorId) => `${API_URL}/connect/user-groups?configurator=${localConfiguratorId}`;
export const API_GET_GROUPS_BY_USERID = (userId) => `${API_URL}/connect/user-groups?users=${userId}`;
export const API_SEARCH_USERS_BY_CONFIGURATOR_ID = (configuratorId, value) => `${API_URL}/connect/users?configurators=${configuratorId}&$search=${value}&$select[]=firstName&$select[]=lastName&$select[]=email&$select[]=type`
export const API_GET_MODELS_FOR_GROUP = (localConfiguratorId) => `${API_URL}/connect/user-groups?configurator=${localConfiguratorId}`;
export const API_POST_GROUP = API_URL + '/connect/user-groups';
export const API_PATCH_GROUP = (groupId) => `${API_URL}/connect/user-groups/${groupId}`;
export const API_DELETE_GROUP = (groupId) => `${API_URL}/connect/user-groups/${groupId}`;
export const API_GET_FAMILY_PRODUCTS = API_URL + '/fdes/tree/product-family';
export const API_GET_AGGREGATED_FAMILY_PRODUCTS = (userId, localConfiguratorId) => `${API_URL}/connect/aggregated-user-product-families/${userId}?configurator=${localConfiguratorId}`;
export const API_GET_DEFAULT_PRODUCT_FAMILIES = () => `${API_URL}/connect/default-configurator-product-families`;
export const API_POST_FAMILY_PRODUCT = API_URL + '/fdes/product-family';
export const API_PATCH_FAMILY_PRODUCTS = (familyProductId) => `${API_URL}/fdes/product-family/${familyProductId}`;
export const API_DELETE_FAMILY_PRODUCT = (familyProductId) => `${API_URL}/fdes/product-family/${familyProductId}`;
export const API_POST_RECOVER_PASSWORD = `${API_URL}/connect/authManagement`;
export const API_POST_FDES = `${API_URL}/fdes/fdes`;
export const API_GET_FDES_METADATA = (fdesId) => `${API_URL}/fdes/fdes-metadata?fdes=${fdesId}`;
export const API_GET_MODELS = (familyProductId) => `${API_URL}/fdes/fdes?productFamily=${familyProductId}`;
export const API_PATCH_FDES = (fdesId) => `${API_URL}/fdes/fdes/${fdesId}`;
export const API_POST_REGISTER = `${API_URL}/connect/register`;
export const API_POST_VERIFICATION = `${API_URL}/connect/authManagement`;
export const API_GET_FDES = (fdesId) => `${API_URL}/fdes/fdes-parameters?fdes=${fdesId}&$sort[order]=1&$paginate=false`;
export const API_POST_PARAMS = `${API_URL}/calculator/calculate`;
export const API_POST_FILE = `${API_URL}/uploader/user-files`;
export const API_GOOGLE_POST_PARAMS = `${API_URL}/google/calculator/calculate`;
export const API_GET_REFERENCE = (standard) => `${API_URL}/calculator/reference${standard ? `?standard=${standard}` : ''}`;
export const API_POST_RESULT = `${API_URL}/fdes/user-fdes`;
export const API_PATCH_RESULT = (calculId) => `${API_URL}/fdes/user-fdes/${calculId}`;
export const API_GET_CALCULS = (userId, localConfiguratorId, params) => `${API_URL}/fdes/user-fdes?user=${userId}&configurator=${localConfiguratorId}&${params}&$select[]=_id&$select[]=title&$select[]=productFamily.id&$select[]=fdes&$select[]=project&$select[]=createdAt&$select[]=updatedAt&$select[]=metrics&$select[]=reports`;
export const API_GET_CALCUL = (calculId) => `${API_URL}/fdes/user-fdes/${calculId}`;
export const API_DELETE_CALCUL = (calculId) => `${API_URL}/fdes/user-fdes/${calculId}`;
export const API_DUPLICATE_CALCUL = (calculId) => `${API_URL}/fdes/user-fdes/${calculId}/duplicate`;
export const API_UPGRADE_CALCUL = (calculId) => `${API_URL}/fdes/user-fdes/${calculId}/upgrade`;
export const API_POST_GENERATE_REPORT = `${API_URL}/report/generate`;
export const API_CONNECT_CONTACT = `${API_URL}/connect/contact`;
export const API_GET_EDEC_CONFIGURATOR = (configuratorId) => `${API_URL}/connect/integrations?configurator=${configuratorId}&code=edec`;
export const API_POST_EDEC_CONFIGURATOR = API_URL + '/connect/integrations';
export const API_PATCH_EDEC_CONFIGURATOR = (EDECConfiguratorId) => `${API_URL}/connect/integrations/${EDECConfiguratorId}`;
export const API_DELETE_EDEC_CONFIGURATOR = (EDECConfiguratorId) => `${API_URL}/connect/integrations/${EDECConfiguratorId}`;
export const API_POST_EDEC_INTEGRATION_PROCESS = API_URL + '/connect/integrations/edec/process';