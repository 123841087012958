export const GET_FAMILY_PRODUCTS = 'GET_FAMILY_PRODUCTS';
export const GET_FAMILY_PRODUCTS_SUCCESS = 'GET_FAMILY_PRODUCTS_SUCCESS';
export const GET_AGGREGATED_FAMILY_PRODUCTS = 'GET_AGGREGATED_FAMILY_PRODUCTS';
export const GET_AGGREGATED_FAMILY_PRODUCTS_SUCCESS = 'GET_AGGREGATED_FAMILY_PRODUCTS_SUCCESS';
export const GET_DEFAULT_PRODUCT_FAMILIES = 'GET_DEFAULT_PRODUCT_FAMILIES';
export const GET_DEFAULT_PRODUCT_FAMILIES_SUCCESS = 'GET_DEFAULT_PRODUCT_FAMILIES_SUCCESS';
export const SAVE_FAMILY_PRODUCT_FORM = 'SAVE_FAMILY_PRODUCT_FORM';
export const UPDATE_FAMILY_PRODUCT_FORM = 'UPDATE_FAMILY_PRODUCT_FORM';
export const UPDATE_FAMILY_PRODUCT_FORM_SUCCESS = 'UPDATE_FAMILY_PRODUCT_FORM_SUCCESS';
export const DELETE_FAMILY_PRODUCT = 'DELETE_FAMILY_PRODUCT';
export const DELETE_FAMILY_PRODUCT_SUCCESS = 'DELETE_FAMILY_PRODUCT_SUCCESS';
export const SAVE_FAMILY_PRODUCT_FDES = 'SAVE_FAMILY_PRODUCT_FDES';
export const SAVE_FAMILY_PRODUCT_FDES_SUCCESS = 'SAVE_FAMILY_PRODUCT_FDES_SUCCESS';
export const SAVE_FAMILY_PRODUCT_FDES_FAILED = 'SAVE_FAMILY_PRODUCT_FDES_FAILED';
export const UPDATE_FAMILY_PRODUCT_FDES = 'UPDATE_FAMILY_PRODUCT_FDES';
export const UPDATE_FAMILY_PRODUCT_FDES_SUCCESS = 'UPDATE_FAMILY_PRODUCT_FDES_SUCCESS';
export const UPDATE_FAMILY_PRODUCT_FDES_FAILED = 'UPDATE_FAMILY_PRODUCT_FDES_FAILED';
export const LOAD_MODEL_FOR_FAMILY_PRODUCT = 'LOAD_MODEL_FOR_FAMILY_PRODUCT';
export const LOAD_MODEL_FOR_FAMILY_PRODUCT_SUCCESS = 'LOAD_MODEL_FOR_FAMILY_PRODUCT_SUCCESS';
export const LOAD_MODEL_FOR_FAMILY_PRODUCT_FAILED = 'LOAD_MODEL_FOR_FAMILY_PRODUCT_FAILED';
export const CLEAN_MODELS = 'CLEAN_MODELS';
export const ENABLE_REPORTS_LOADER = 'ENABLE_REPORTS_LOADER';
export const DISABLE_REPORTS_LOADER = 'DISABLE_REPORTS_LOADER';
export const ENABLE_CALCUL_LOADER = 'ENABLE_CALCUL_LOADER';
export const DISABLE_CALCUL_LOADER = 'DISABLE_CALCUL_LOADER';
export const ENABLE_PDF_REPORT_LOADER = 'ENABLE_PDF_REPORT_LOADER';
export const DISABLE_PDF_REPORT_LOADER = 'DISABLE_PDF_REPORT_LOADER';
export const ENABLE_XML_REPORT_LOADER = 'ENABLE_XML_REPORT_LOADER';
export const DISABLE_XML_REPORT_LOADER = 'DISABLE_XML_REPORT_LOADER';