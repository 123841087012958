import produce from "immer";
import {
    GET_FAMILY_PRODUCTS_SUCCESS,
    GET_AGGREGATED_FAMILY_PRODUCTS_SUCCESS,
    DELETE_FAMILY_PRODUCT_SUCCESS,
    LOAD_MODEL_FOR_FAMILY_PRODUCT_SUCCESS, CLEAN_MODELS,
    GET_DEFAULT_PRODUCT_FAMILIES_SUCCESS
} from "../constants";

const initialState = {
    familyProducts: [],
    models: [],
    aggregatedFamilyProducts: [],
};

const contextReducer = produce((draft = initialState, action) => {
    switch (action.type) {
        case GET_FAMILY_PRODUCTS_SUCCESS:
            storeFamilyProducts(draft, action.payload);
            break;
        case GET_AGGREGATED_FAMILY_PRODUCTS_SUCCESS:
            storeAggregatedFamilyProducts(draft, action.payload);
            break;
        case GET_DEFAULT_PRODUCT_FAMILIES_SUCCESS:
            storeAggregatedFamilyProducts(draft, action.payload);
            break;
        case DELETE_FAMILY_PRODUCT_SUCCESS:
            removeFamilyProduct(draft, action.payload);
            break;
        case LOAD_MODEL_FOR_FAMILY_PRODUCT_SUCCESS:
            storeModels(draft, action.payload);
            break;
        case CLEAN_MODELS:
            draft.models = [];
            break;
        default:
            return draft;
    }
});

function removeFamilyProduct (draft, payload) {
    draft.familyProducts.splice(draft.familyProducts.findIndex(familyProduct => familyProduct._id === payload), 1);
}
function storeFamilyProducts (draft, payload) {
    draft.familyProducts = payload;
}
function storeAggregatedFamilyProducts (draft, payload) {
    draft.aggregatedFamilyProducts = Object.values(payload);
}
function storeModels (draft, payload) {
    draft.models = payload.data;
}
export default contextReducer;