import React, { useEffect, useState } from 'react';
import FamilyProductRaw from "./FamilyProductRaw";


const FamilyProduct = ({ getFamilyProductsRequest, familyProducts, deleteFamilyProduct, configurator, getConfiguratorsRequest }) => {
    const [selectedOption, setSelectedOption] = useState("");


    useEffect(() => {
        getFamilyProductsRequest();
        getConfiguratorsRequest();
    }, [getFamilyProductsRequest, getConfiguratorsRequest]);

    function findProductFamilliesOnConfigurator(id) {
        return configurator && configurator.configurators.length > 0 &&
            configurator.configurators.find(x => x._id === selectedOption)?.productFamilies?.map(x => x.id).includes(id)
    }

    return (
        <div className="users-list-table">
            <div className="card">
                <div className="card-content">
                    <div className="card-body">
                        <div className='col-xl-4 float-left mb-1 p-0'>
                            <b>Filtrer par configurateur</b>
                            <select
                                className="form-control p-0"
                                value={selectedOption}
                                onChange={(e) => { setSelectedOption(e.target.value) }}
                                placeholder={"Sélectionner un configurateur"}>
                                {configurator.configurators.map(configurator => <option key={configurator._id} value={configurator._id}>{configurator.title}</option>)}
                            </select>
                        </div>
                        <div className="table-responsive">
                            <table id="users-list-datatable" className="table">
                                <thead>
                                    <tr>
                                        <th>Nom</th>
                                        <th>Descriptif</th>
                                        <th>Image</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {familyProducts && familyProducts.length > 0 && (
                                        familyProducts.map((familyProduct, index) => {
                                            return <div key={'fp' + index}> {
                                                selectedOption ?
                                                    findProductFamilliesOnConfigurator(familyProduct._id) ?
                                                        <FamilyProductRaw key={familyProduct._id + index} deleteFamilyProduct={deleteFamilyProduct} familyProduct={familyProduct} paddingRem={0} />
                                                        :
                                                        <></>
                                                    :
                                                    <FamilyProductRaw key={familyProduct._id + index} deleteFamilyProduct={deleteFamilyProduct} familyProduct={familyProduct} paddingRem={0} defaultOpen={false} />
                                            }
                                            </div>
                                        })
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

};

export default FamilyProduct;
