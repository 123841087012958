import React, {useEffect, useState} from "react";
import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from "draft-js";
import * as Yup from "yup";
import {Formik} from "formik";
import {Editor} from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import {Link} from "react-router-dom";
import Loader from "../../../common/components/Loader";
import {
  logoPositionKeyToLabel,
  themeHeaderKeyToLabel,
  themeMenuKeyToLabel,
  anonymousModeKeyToLabel,
} from "../../../utils/transformer";

const ConfiguratorForm = ({
  sendConfiguratorForm,
  configurator,
  action = "CREATE",
  configuratorFormUpdate,
  familyProducts,
  isLoading,
  EDECIntegration,
  sendEDECConfiguratorForm,
  EDECConfiguratorFormUpdate,
}) => {
  function menuIsActiveAbout () {
    if (
      configurator?.documentation?.about?.menu
    ) {
      if (configurator.documentation.about.menu.isActive) return "1";
      return "0";
    } else return "1";
  }

  function menuIsActiveFdesMeres () {
    if (
      configurator?.documentation?.fdesMeres?.menu
    ) {
      if (configurator.documentation.fdesMeres.menu.isActive) return "1";
      return "0";
    } else return "1";
  }

  function menuIsActiveVideoTutorial () {
    if (
      configurator?.documentation?.videoTutorial?.menu
    ) {
      if (configurator.documentation.videoTutorial.menu.isActive) return "1";
      return "0";
    } else return "1";
  }

  function menuIsActiveGuide () {
    if (
      configurator?.documentation?.about &&
      configurator?.documentation?.guide?.menu
    ) {
      if (configurator.documentation.guide.menu.isActive) return "1";
      return "0";
    } else return "1";
  }

  function menuIsActiveMethodology () {
    if (
      configurator?.documentation?.about &&
      configurator?.documentation?.methodology?.menu
    ) {
      if (configurator.documentation.methodology.menu.isActive) return "1";
      return "0";
    } else return "1";
  }

  function isActiveButton1 () {
    if (
      configurator?.content?.homepage?.buttons?.button_1
    ) {
      if (configurator.content.homepage.buttons.button_1.isActive) return "1";
      return "0";
    } else return "1";
  }

  function isActiveButton2 () {
    if (
      configurator?.content?.homepage?.buttons?.button_2
    ) {
      if (configurator.content.homepage.buttons.button_2.isActive) return "1";
      return "0";
    } else return "1";
  }

  const initialValues = {
    id: configurator ? configurator.id : "",
    name: configurator ? configurator.title : "",
    code: configurator ? configurator.code : "",
    color: configurator ? configurator.color : "",
    domain: configurator ? configurator.domain : "",
    domainIsPublic: configurator?.domainIsPublic ? "1" : "0",
    adminContactEmail:
      configurator && configurator.email && configurator.email.adminContactEmail
        ? configurator.email.adminContactEmail
        : "",
    theme: {
      colors: {
        primary:
          configurator?.theme?.colors
            ? configurator.theme.colors.primary
            : "",
        secondary:
          configurator?.theme?.colors
            ? configurator.theme.colors.secondary
            : "",
        primaryText:
          configurator?.theme?.colors
            ? configurator.theme.colors.primaryText
            : "",
        secondaryText: "#1B2942",
      },
      logoPosition:
        configurator?.theme
          ? configurator.theme.logoPosition
          : "",
      secondaryLogoPosition:
        configurator?.theme
          ? configurator.theme.secondaryLogoPosition
          : "",
      headerStyle:
        configurator?.theme
          ? configurator.theme.headerStyle
          : "",
      menuStyle:
        configurator?.theme
          ? configurator.theme.menuStyle
          : "",
    },
    file: "",
    secondaryLogo: "",
    secondaryLogoUrl: configurator ? configurator.secondaryLogoUrl : "",
    aboutImage: "",
    guideFile: "",
    methodologyFile: "",
    familyProducts:
      configurator && configurator.productFamilies
        ? configurator.productFamilies
        : [],
    content: {
      homepage: {
        title:
          configurator?.content?.homepage?.title
            ? configurator.content.homepage.title
            : "",
        text:
          configurator?.content?.homepage?.text
            ? configurator.content.homepage.text
            : "",
        video:
          configurator?.content?.homepage?.video
            ? configurator.content.homepage.video
            : "",
        buttons: {
          button_1: {
            isActive: isActiveButton1(),
            label:
              configurator?.content?.homepage?.buttons?.button_1?.label
                ? configurator.content.homepage.buttons.button_1.label
                : "En savoir plus",
          },
          button_2: {
            isActive: isActiveButton2(),
            label:
              configurator?.content?.homepage?.buttons?.button_2?.label
                ? configurator.content.homepage.buttons.button_2.label
                : "Configurer une FDES",
          },
        },
      },
      productFamilies: {
        title:
          configurator?.content?.productFamilies?.title
            ? configurator.content.productFamilies.title
            : "",
        text:
          configurator?.content?.productFamilies?.text
            ? configurator.content.productFamilies.text
            : "",
      },
      cgu:
        configurator?.content?.cgu
          ? configurator.content.cgu
          : "",
    },
    security: {
      allowRegister: configurator?.security?.allowRegister ? "1" : "0",
      accessControl: {
        anonymousMode:
          configurator?.security?.accessControl?.anonymousMode
            ? configurator.security.accessControl.anonymousMode
            : "",
      },
    },
    documentation: {
      about: {
        title:
          configurator?.documentation?.about
            ? configurator.documentation.about.title
            : "",
        description:
          configurator?.documentation?.about
            ? configurator.documentation.about.description
            : "",
        image:
          configurator?.documentation?.about
            ? configurator.documentation.about.image
            : "",
        menu: {
          _id:
            configurator?.documentation?.about?.menu?._id
              ? configurator.documentation.about.menu._id
              : "",
          isActive: menuIsActiveAbout(),
          label:
            configurator?.documentation?.about?.menu?.label
              ? configurator.documentation.about.menu.label
              : "À propos",
        },
      },
      fdesMeres: {
        title:
          configurator?.documentation?.fdesMeres
            ? configurator.documentation.fdesMeres.title
            : "",
        description:
          configurator?.documentation?.fdesMeres
            ? configurator.documentation.fdesMeres.description
            : "",
        elements:
          configurator?.documentation?.fdesMeres
            ? configurator.documentation.fdesMeres.elements
            : [],
        menu: {
          _id:
            configurator?.documentation?.fdesMeres?.menu._id
              ? configurator.documentation.fdesMeres.menu._id
              : "",
          isActive: menuIsActiveFdesMeres(),
          label:
            configurator?.documentation?.fdesMeres?.menu?.label
              ? configurator.documentation.fdesMeres.menu.label
              : "FDES mères",
        },
      },
      videoTutorial: {
        title:
          configurator?.documentation?.videoTutorial
            ? configurator.documentation.videoTutorial.title
            : "",
        description:
          configurator?.documentation?.videoTutorial
            ? configurator.documentation.videoTutorial.description
            : "",
        video:
          configurator?.documentation?.videoTutorial
            ? configurator.documentation.videoTutorial.video
            : "",
        menu: {
          _id:
            configurator?.documentation?.videoTutorial?.menu?._id
              ? configurator.documentation.videoTutorial.menu._id
              : "",
          isActive: menuIsActiveVideoTutorial(),
          label:
            configurator?.documentation?.videoTutorial?.menu?.label
              ? configurator.documentation.videoTutorial.menu.label
              : "Tutoriels",
        },
      },
      guide: {
        title:
          configurator?.documentation?.guide
            ? configurator.documentation.guide.title
            : "",
        description:
          configurator?.documentation?.guide
            ? configurator.documentation.guide.description
            : "",
        file:
          configurator?.documentation?.guide
            ? configurator.documentation.guide.file
            : "",
        label:
          configurator?.documentation?.guide
            ? configurator.documentation.guide.label
            : "",
        menu: {
          _id:
            configurator?.documentation?.guide?.menu?._id
              ? configurator.documentation.guide.menu._id
              : "",
          isActive: menuIsActiveGuide(),
          label:
            configurator?.documentation?.guide?.menu?.label
              ? configurator.documentation.guide.menu.label
              : "Guides",
        },
      },
      methodology: {
        title:
          configurator?.documentation?.methodology
            ? configurator.documentation.methodology.title
            : "",
        description:
          configurator?.documentation?.methodology
            ? configurator.documentation.methodology.description
            : "",
        file:
          configurator?.documentation?.methodology
            ? configurator.documentation.methodology.file
            : "",
        label:
          configurator?.documentation?.methodology
            ? configurator.documentation.methodology.label
            : "",
        menu: {
          _id:
            configurator?.documentation?.methodology?.menu?._id
              ? configurator.documentation.methodology.menu._id
              : "",
          isActive: menuIsActiveMethodology(),
          label:
            configurator?.documentation?.methodology?.menu?.label
              ? configurator.documentation.methodology.menu.label
              : "Méthodologie",
        },
      },
      faq: {
        elements:
          configurator?.documentation?.faq ?
            configurator.documentation.faq.elements
            : [{title: "", description: ""}],
        allowOnMenu: configurator?.documentation?.faq.hasOwnProperty('allowOnMenu') ?
          configurator.documentation.faq.allowOnMenu ? "1" : "0"
          : "1"
      },
    },
    EDECConfiguratorId:
      EDECIntegration?.configurator === configurator?.id
        ? EDECIntegration._id
        : "",
    EDECConfigurator:
      EDECIntegration?.configurator === configurator?.id &&
        EDECIntegration?.data &&
        EDECIntegration.data[0].key === "EDEC-Configurator"
        ? EDECIntegration.data[0].value
        : "",
    EDECKeyConfigurator:
      EDECIntegration?.configurator === configurator?.id &&
        EDECIntegration?.data &&
        EDECIntegration.data[1].key === "EDEC-CleConfigurator"
        ? EDECIntegration.data[1].value
        : "",
  };

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [familyProductState, setFamilyProductState] = useState(
    EditorState.createEmpty()
  );
  const [documentationAboutState, setDocumentationAboutState] = useState(
    EditorState.createEmpty()
  );
  const [documentationVideoTutorialState, setDocumentationVideoTutorialState] =
    useState(EditorState.createEmpty());
  const [documentationGuideState, setDocumentationGuideState] = useState(
    EditorState.createEmpty()
  );
  const [documentationMethodologyState, setDocumentationMethodologyState] =
    useState(EditorState.createEmpty());
  const [documentationFdesMereState, setDocumentationFdesMereState] = useState(
    EditorState.createEmpty()
  );
  const [documentationFaqState, setDocumentationFaqState] = useState([
    EditorState.createEmpty(),
  ]);

  useEffect(() => {
    if (
      configurator?.content?.homepage?.text
    ) {
      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(configurator.content.homepage.text)
          )
        )
      );
    }
    if (
      configurator?.content?.productFamilies?.text
    ) {
      setFamilyProductState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(configurator.content.productFamilies.text)
          )
        )
      );
    }
    if (
      configurator?.documentation?.about?.description
    ) {
      setDocumentationAboutState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(configurator.documentation.about.description)
          )
        )
      );
    }
    if (
      configurator?.documentation?.videoTutorial?.description
    ) {
      setDocumentationVideoTutorialState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(
              configurator.documentation.videoTutorial.description
            )
          )
        )
      );
    }
    if (
      configurator?.documentation?.guide?.description
    ) {
      setDocumentationGuideState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(configurator.documentation.guide.description)
          )
        )
      );
    }
    if (
      configurator?.documentation?.methodology?.description
    ) {
      setDocumentationMethodologyState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(configurator.documentation.methodology.description)
          )
        )
      );
    }
    if (
      configurator?.documentation?.fdesMeres?.description
    ) {
      setDocumentationFdesMereState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(configurator.documentation.fdesMeres.description)
          )
        )
      );
    }
    if (
      configurator?.documentation?.faq?.elements
    ) {
      setDocumentationFaqState(
        configurator.documentation.faq.elements.map((x) =>
          EditorState.createWithContent(
            ContentState.createFromBlockArray(
              convertFromHTML(x.text ? x.text : "")
            )
          )
        )
      );
    }
  }, [configurator]);

  function onChangeFile (event, setFieldValue, field) {
    setFieldValue(field, event.target.files[0]);
  }

  function handleTextChange (editor, setter, setFieldValue, field) {
    setter(editor);
    setFieldValue(field, draftToHtml(convertToRaw(editor.getCurrentContent())));
  }

  function handleTextChangeForFaq (editor, setter, setFieldValue, field, index) {
    documentationFaqState[index] = editor;
    setter(documentationFaqState);
    setFieldValue(field, draftToHtml(convertToRaw(editor.getCurrentContent())));
  }

  function handleFamilyProductChange (familyProduct, setFieldValue, values) {
    let familyProducts = [...values.familyProducts];
    if (familyProducts.map((x) => x.id).includes(familyProduct._id)) {
      familyProducts = familyProducts.filter((x) => x.id !== familyProduct._id);
      if (familyProduct.children && familyProduct.children.length > 0) {
        familyProducts = familyProducts.filter(
          (x) =>
            !familyProduct.children
              .map((children) => children._id)
              .includes(x.id)
        );
      }
    } else {
      familyProducts.push({id: familyProduct._id, order: ""});
      if (familyProduct.children && familyProduct.children.length > 0) {
        familyProduct.children.map((children) =>
          familyProducts.map((x) => x.id).includes(children._id)
            ? ""
            : familyProducts.push({
              id: children._id,
              order: "",
            })
        );
      }
    }

    setFieldValue("familyProducts", familyProducts);
  }

  function handleOrderChange (value, familyProductId, setFieldValue, values) {
    let familyProducts = values.familyProducts;
    familyProducts = familyProducts.filter((x) => x.id !== familyProductId);
    familyProducts.push({id: familyProductId, order: value});
    setFieldValue("familyProducts", familyProducts);
  }

  function displayFamilyProduct (
    familyProduct,
    paddingRem,
    setFieldValue,
    values
  ) {
    return (
      <>
        <fieldset
          className={`checkboxsas ml-${paddingRem}`}
          key={familyProduct._id}
        >
          <label>
            <input
              checked={values.familyProducts
                .map((x) => x.id)
                .includes(familyProduct._id)}
              onChange={() =>
                handleFamilyProductChange(familyProduct, setFieldValue, values)
              }
              type="checkbox"
              value={familyProduct._id}
              className="mr-1"
            />
            {familyProduct.name}
          </label>
          {values.familyProducts
            .map((x) => x.id)
            .includes(familyProduct._id) && (
              <label className="float-right">
                Ordre:
                <input
                  className="ml-2 col-md-3"
                  type="number"
                  value={
                    values.familyProducts.find((x) => x.id === familyProduct._id)
                      ? values.familyProducts.find(
                        (x) => x.id === familyProduct._id
                      ).order
                      : ""
                  }
                  onChange={(e) =>
                    handleOrderChange(
                      e.target.value,
                      familyProduct._id,
                      setFieldValue,
                      values
                    )
                  }
                />
              </label>
            )}
        </fieldset>
        {familyProduct &&
          familyProduct.children &&
          familyProduct.children.length > 0 &&
          familyProduct.children.map((children) =>
            displayFamilyProduct(
              children,
              paddingRem + 2,
              setFieldValue,
              values
            )
          )}
      </>
    );
  }

  function handleAddElement (values, setFieldValue) {
    const elements = [...values.documentation.fdesMeres.elements];
    elements.push({
      title: "",
      description: "",
      file: "",
      fileToSend: "",
      image: "",
      imageToSend: "",
      order: "",
    });
    setFieldValue("documentation.fdesMeres.elements", elements);
  }

  function handleDeleteElement (values, setFieldValue, index) {
    const elements = [...values.documentation.fdesMeres.elements];
    elements.splice(index, 1);
    setFieldValue("documentation.fdesMeres.elements", elements);
  }

  function handleAddElementForFaq (values, setFieldValue) {
    const elements = [...values.documentation.faq.elements];
    elements.push({
      title: "",
      description: "",
    });
    documentationFaqState.push(EditorState.createEmpty());
    setFieldValue("documentation.faq.elements", elements);
  }

  function handleDeleteElementForFaq (values, setFieldValue, index) {
    const elements = [...values.documentation.faq.elements];
    elements.splice(index, 1);
    setDocumentationFaqState(
      elements.map((x) =>
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(x.text ? x.text : "")
          )
        )
      )
    );
    setFieldValue("documentation.faq.elements", elements);
  }

  return (
    <>
      <Loader isActive={isLoading} />
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values, {setSubmitting}) => {
          setTimeout(() => {
            setSubmitting(false);
            if (action === "CREATE") {
              sendConfiguratorForm(values);
            } else if (action === "UPDATE") {
              configuratorFormUpdate(values);
              if (
                values.EDECConfigurator !== "" &&
                values.EDECKeyConfigurator !== ""
              ) {
                if (EDECIntegration && EDECIntegration._id) {
                  EDECConfiguratorFormUpdate(values);
                } else {
                  sendEDECConfiguratorForm(values);
                }
              }
            }
          }, 500);
        }}
        validationSchema={Yup.object().shape({
          id: Yup.string(),
          name: Yup.string().required("Champ Requis"),
          code: Yup.number()
            .typeError("Le code doit être un nombre")
            .required("Champ Requis"),
          domain: Yup.string().required("Champ Requis"),
          domainIsPublic: Yup.boolean(),
          color: Yup.string(),
          adminContactEmail: Yup.string(),
          theme: Yup.object().shape({
            colors: Yup.object().shape({
              primary: Yup.string(),
              secondary: Yup.string(),
              primaryText: Yup.string(),
              secondaryText: Yup.string(),
            }),
            logoPosition: Yup.string(),
            secondaryLogoPosition: Yup.string().when(
              "logoPosition",
              (logoPosition, schema) =>
                schema.notOneOf(
                  [logoPosition],
                  "La position des logos doivent être différentes"
                )
            ),
            headerStyle: Yup.string(),
            menuStyle: Yup.string(),
          }),
          security: Yup.object().shape({
            allowRegister: Yup.boolean(),
          }),
          file: Yup.mixed(),
          familyProducts: Yup.array(),
          content: Yup.object().shape({
            homepage: Yup.object().shape({
              title: Yup.string(),
              text: Yup.string(),
              video: Yup.string(),
              buttons: Yup.object().shape({
                button_1: Yup.object().shape({
                  isActive: Yup.boolean(),
                  label: Yup.string(),
                }),
                button_2: Yup.object().shape({
                  isActive: Yup.boolean(),
                  label: Yup.string(),
                }),
              }),
            }),
            productFamilies: Yup.object().shape({
              title: Yup.string(),
              text: Yup.string(),
            }),
          }),
          documentation: Yup.object().shape({
            about: Yup.object().shape({
              title: Yup.string(),
              description: Yup.string(),
              menu: Yup.object().shape({
                _id: Yup.string(),
                isActive: Yup.boolean(),
                label: Yup.string(),
              }),
            }),
            videoTutorial: Yup.object().shape({
              title: Yup.string(),
              description: Yup.string(),
              menu: Yup.object().shape({
                _id: Yup.string(),
                isActive: Yup.boolean(),
                label: Yup.string(),
              }),
            }),
            guide: Yup.object().shape({
              title: Yup.string(),
              description: Yup.string(),
              menu: Yup.object().shape({
                _id: Yup.string(),
                isActive: Yup.boolean(),
                label: Yup.string(),
              }),
            }),
            methodology: Yup.object().shape({
              title: Yup.string(),
              description: Yup.string(),
              menu: Yup.object().shape({
                _id: Yup.string(),
                isActive: Yup.boolean(),
                label: Yup.string(),
              }),
            }),
          }),
          EDECConfigurator: Yup.string(),
          EDECKeyConfigurator: Yup.string().when(
            "EDECConfigurator", {
            is: (value) => value?.length > 0,
            then: Yup.string().required("Champ Requis"),
            otherwise: Yup.string()
          }
          ),
        })}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          } = props;

          return (
            <div className="card">
              <div className="card-content collapse show">
                <div className="card-body">
                  <form
                    className="form form-horizontal striped-rows form-bordered"
                    onSubmit={handleSubmit}
                  >
                    <ul className="nav nav-tabs nav-underline" role="tablist">
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          id="active-tab1"
                          data-toggle="tab"
                          href="#general"
                          aria-controls="general-tab"
                          role="tab"
                          aria-selected="true"
                        >
                          Informations générales et aspect
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={`nav-link`}
                          id="link-tab2"
                          data-toggle="tab"
                          href="#documentation"
                          aria-controls="documentation-tab"
                          role="tab"
                          aria-selected="false"
                        >
                          Gestion des textes et de la documentation
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={`nav-link`}
                          id="link-tab1"
                          data-toggle="tab"
                          href="#familyProduct"
                          aria-controls="familyProduct-tab"
                          role="tab"
                          aria-selected="false"
                        >
                          Gestion des familles de produits
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content">
                      <div
                        id="general"
                        className={`form-body tab-pane active in`}
                        aria-labelledby="general-tab"
                        role="tabpanel"
                      >
                        <div className="form-body">
                          <h4 className="form-section">Généralités</h4>
                          <div className="form-group row has-danger">
                            <label
                              htmlFor="name"
                              className="col-md-3 label-control"
                            >
                              Nom du configurateur (onglet navigateur)
                            </label>
                            <div className="col-md-9">
                              <input
                                type="text"
                                id="name"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.name}
                              />
                              {errors.name && touched.name && (
                                <div className="mt-1 danger">{errors.name}</div>
                              )}
                            </div>
                          </div>
                          <div className="form-group row has-danger">
                            <label
                              htmlFor="code"
                              className="col-md-3 label-control"
                            >
                              Code du configurateur (4 chiffres)
                            </label>
                            <div className="col-md-9">
                              <input
                                type="text"
                                id="code"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.code}
                              />
                              {errors.code && touched.code && (
                                <div className="mt-1 danger">{errors.code}</div>
                              )}
                            </div>
                          </div>
                          <div className="form-group row has-danger">
                            <label
                              htmlFor="domain"
                              className="col-md-3 label-control"
                            >
                              URL du configurateur
                            </label>
                            <div className="col-md-9">
                              <input
                                type="text"
                                id="domain"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.domain}
                              />
                              {errors.domain && touched.domain && (
                                <div className="mt-1 danger">
                                  {errors.domain}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="form-group row has-danger">
                            <label
                              htmlFor="domainIsPublic"
                              className="col-md-3 label-control"
                            >
                              URL exportée dans XML
                            </label>
                            <div className="col-md-9">
                              <label className="mr-4">
                                Oui
                                <input
                                  type="radio"
                                  id="domainIsPublic"
                                  checked={values.domainIsPublic === "1"}
                                  className="ml-1"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={"1"}
                                />
                              </label>
                              <label>
                                Non
                                <input
                                  type="radio"
                                  id="domainIsPublic"
                                  checked={values.domainIsPublic === "0"}
                                  className="ml-1"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={"0"}
                                />
                              </label>
                            </div>
                          </div>
                          <div className="form-group row has-danger">
                            <label
                              htmlFor="security.accessControl.anonymousMode"
                              className="col-md-3 label-control"
                            >
                              Pages accessibles hors connexion
                            </label>
                            <div className="col-md-9">
                              <select
                                className="form-control"
                                name="security.accessControl.anonymousMode"
                                id="security.accessControl.anonymousMode"
                                value={
                                  values.security.accessControl.anonymousMode
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                              >
                                <option value="" />
                                {Object.entries(anonymousModeKeyToLabel).map(
                                  (value) => (
                                    <option key={value[0]} value={value[0]}>
                                      {value[1]}
                                    </option>
                                  )
                                )}
                              </select>
                            </div>
                          </div>
                          <div className="form-group row has-danger">
                            <label
                              htmlFor="security.allowRegister"
                              className="col-md-3 label-control"
                            >{`Autoriser
                              l'inscription`}</label>
                            <div className="col-md-9">
                              <label className="mr-4">
                                Oui
                                <input
                                  type="radio"
                                  id="security.allowRegister"
                                  checked={
                                    values.security.allowRegister === "1"
                                  }
                                  className="ml-1"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={"1"}
                                />
                              </label>
                              <label>
                                Non
                                <input
                                  type="radio"
                                  id="security.allowRegister"
                                  checked={
                                    values.security.allowRegister === "0"
                                  }
                                  className="ml-1"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={"0"}
                                />
                              </label>
                            </div>
                          </div>
                          <div className="form-group row has-danger">
                            <label
                              htmlFor="adminContactEmail"
                              className="col-md-3 label-control"
                            >
                              Email Admin
                            </label>
                            <div className="col-md-9">
                              <input
                                type="text"
                                id="adminContactEmail"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.adminContactEmail}
                              />
                              {errors.adminContactEmail &&
                                touched.adminContactEmail && (
                                  <div className="mt-1 danger">
                                    {errors.adminContactEmail}
                                  </div>
                                )}
                            </div>
                          </div>
                          <h4 className="form-section">Charte graphique</h4>
                          <div className="form-group row has-danger">
                            <label
                              htmlFor="theme.headerStyle"
                              className="col-md-3 label-control"
                            >
                              Thème header
                            </label>
                            <div className="col-md-9">
                              <select
                                className="form-control"
                                name="theme.headerStyle"
                                id="theme.headerStyle"
                                value={values.theme.headerStyle}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              >
                                <option value="" />
                                {Object.entries(themeHeaderKeyToLabel).map(
                                  (value) => (
                                    <option key={value[0]} value={value[0]}>
                                      {value[1]}
                                    </option>
                                  )
                                )}
                              </select>
                            </div>
                          </div>
                          <div className="form-group row has-danger">
                            <label
                              htmlFor="theme.menuStyle"
                              className="col-md-3 label-control"
                            >
                              Thème menu
                            </label>
                            <div className="col-md-9">
                              <select
                                className="form-control"
                                name="theme.menuStyle"
                                id="theme.menuStyle"
                                value={values.theme.menuStyle}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              >
                                <option value="" />
                                {Object.entries(themeMenuKeyToLabel).map(
                                  (value) => (
                                    <option key={value[0]} value={value[0]}>
                                      {value[1]}
                                    </option>
                                  )
                                )}
                              </select>
                            </div>
                          </div>
                          <div className="form-group row has-danger">
                            <label
                              htmlFor="color"
                              className="col-md-3 label-control"
                            >
                              Couleur header
                            </label>
                            <div className="col-md-9">
                              <input
                                type="color"
                                id="color"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.color}
                              />
                              {errors.color && touched.color && (
                                <div className="mt-1 danger">
                                  {errors.color}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="form-group row has-danger">
                            <label
                              htmlFor="theme.colors.primary"
                              className="col-md-3 label-control"
                            >
                              Couleur bandeaux
                            </label>
                            <div className="col-md-9">
                              <input
                                type="color"
                                id="theme.colors.primary"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.theme.colors.primary}
                              />
                            </div>
                          </div>
                          <div className="form-group row has-danger">
                            <label
                              htmlFor="theme.colors.secondary"
                              className="col-md-3 label-control"
                            >{`Couleur bulle
                              d'aide`}</label>
                            <div className="col-md-9">
                              <input
                                type="color"
                                id="theme.colors.secondary"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.theme.colors.secondary}
                              />
                            </div>
                          </div>
                          <div className="form-group row has-danger">
                            <label
                              htmlFor="theme.colors.primaryText"
                              className="col-md-3 label-control"
                            >{`Couleur
                              d'intéraction`}</label>
                            <div className="col-md-9">
                              <input
                                type="color"
                                id="theme.colors.primaryText"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.theme.colors.primaryText}
                              />
                            </div>
                          </div>
                          <div className="form-group row has-danger">
                            <label
                              htmlFor="logo"
                              className="col-md-3 label-control"
                            >
                              Logo principal
                            </label>
                            <div className="col-md-9">
                              <input
                                type="file"
                                id="logo"
                                className="form-control"
                                onChange={(e) =>
                                  onChangeFile(e, setFieldValue, "file")
                                }
                                onBlur={handleBlur}
                              />
                              {errors.logo && touched.logo && (
                                <div className="mt-1 danger">{errors.logo}</div>
                              )}
                              {configurator && configurator.logo !== "" && (
                                <div className="max-image">
                                  Image actuelle :{" "}
                                  <img
                                    alt=""
                                    className="img-fluid"
                                    src={configurator.logo}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="form-group row has-danger">
                            <label
                              htmlFor="theme.logoPosition"
                              className="col-md-3 label-control"
                            >
                              Position du logo principal
                            </label>
                            <div className="col-md-9">
                              <select
                                className="form-control"
                                name="theme.logoPosition"
                                id="theme.logoPosition"
                                value={values.theme.logoPosition}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              >
                                <option value="" />
                                {Object.entries(logoPositionKeyToLabel).map(
                                  (value) => (
                                    <option key={value[0]} value={value[0]}>
                                      {value[1]}
                                    </option>
                                  )
                                )}
                              </select>
                              {errors.theme &&
                                errors.theme.logoPosition &&
                                touched.theme &&
                                touched.theme.logoPosition && (
                                  <div className="mt-1 danger">
                                    {errors.theme.logoPosition}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="form-group row has-danger">
                            <label
                              htmlFor="secondaryLogo"
                              className="col-md-3 label-control"
                            >
                              Logo secondaire
                            </label>
                            <div className="col-md-9">
                              <input
                                type="file"
                                id="secondaryLogo"
                                className="form-control"
                                onChange={(e) =>
                                  onChangeFile(
                                    e,
                                    setFieldValue,
                                    "secondaryLogo"
                                  )
                                }
                                onBlur={handleBlur}
                              />
                              {errors.secondaryLogo &&
                                touched.secondaryLogo && (
                                  <div className="mt-1 danger">
                                    {errors.secondaryLogo}
                                  </div>
                                )}
                              {configurator &&
                                configurator.secondaryLogo !== "" && (
                                  <div className="max-image">
                                    Image actuelle :{" "}
                                    <img
                                      alt=""
                                      className="img-fluid"
                                      src={configurator.secondaryLogo}
                                    />
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="form-group row has-danger">
                            <label
                              htmlFor="theme.secondaryLogoPosition"
                              className="col-md-3 label-control"
                            >
                              Position du logo secondaire
                            </label>
                            <div className="col-md-9">
                              <select
                                className="form-control"
                                name="theme.secondaryLogoPosition"
                                id="theme.secondaryLogoPosition"
                                value={values.theme.secondaryLogoPosition}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              >
                                <option value="" />
                                {Object.entries(logoPositionKeyToLabel).map(
                                  (value) => (
                                    <option key={value[0]} value={value[0]}>
                                      {value[1]}
                                    </option>
                                  )
                                )}
                              </select>
                              {errors.theme &&
                                errors.theme.secondaryLogoPosition &&
                                touched.theme &&
                                touched.theme.secondaryLogoPosition && (
                                  <div className="mt-1 danger">
                                    {errors.theme.secondaryLogoPosition}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="form-group row has-danger">
                            <label
                              htmlFor="secondaryLogoUrl"
                              className="col-md-3 label-control"
                            >
                              URL du logo secondaire
                            </label>
                            <div className="col-md-9">
                              <input
                                type="text"
                                id="secondaryLogoUrl"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.secondaryLogoUrl}
                              />
                              {errors.secondaryLogoUrl &&
                                touched.secondaryLogoUrl && (
                                  <div className="mt-1 danger">
                                    {errors.secondaryLogoUrl}
                                  </div>
                                )}
                            </div>
                          </div>
                          <h4 className="form-section">EDEC</h4>
                          <div className="form-group row has-danger">
                            <label
                              htmlFor="EDECConfigurator"
                              className="col-md-3 label-control"
                            >
                              EDEC-Configurateur
                            </label>
                            <div className="col-md-9">
                              <input
                                type="text"
                                id="EDECConfigurator"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.EDECConfigurator}
                              />
                            </div>
                          </div>
                          <div className="form-group row has-danger">
                            <label
                              htmlFor="EDECKeyConfigurator"
                              className="col-md-3 label-control"
                            >
                              EDEC-Cle Configurateur
                            </label>
                            <div className="col-md-9">
                              <input
                                type="text"
                                id="EDECKeyConfigurator"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.EDECKeyConfigurator}
                              />
                              {errors.EDECKeyConfigurator &&
                                touched.EDECKeyConfigurator && (
                                  <div className="mt-1 danger">
                                    {errors.EDECKeyConfigurator}
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        id="documentation"
                        className={`form-body tab-pane`}
                        aria-labelledby="documentation-tab"
                        role="tabpanel"
                      >
                        <div className="form-body">
                          <h4 className="form-section">{`Bloc d'accueil`}</h4>
                          <div className="form-group row has-danger">
                            <label
                              htmlFor="content.homepage.title"
                              className="col-md-3 label-control"
                            >
                              Titre
                            </label>
                            <div className="col-md-9">
                              <input
                                type="text"
                                id="content.homepage.title"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.content.homepage.title}
                              />
                              {errors.content &&
                                errors.content.homepage &&
                                errors.content.homepage.title &&
                                touched.content &&
                                touched.content.homepage &&
                                touched.content.homepage.title && (
                                  <div className="mt-1 danger">
                                    {errors.content.homepage.title}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="form-group row has-danger">
                            <label
                              htmlFor="content.homepage.text"
                              className="col-md-3 label-control"
                            >
                              Texte
                            </label>
                            <div className="col-md-9">
                              <Editor
                                editorState={editorState}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={(e) =>
                                  handleTextChange(
                                    e,
                                    setEditorState,
                                    setFieldValue,
                                    "content.homepage.text"
                                  )
                                }
                              />
                              {errors.content &&
                                errors.content.homepage &&
                                errors.content.homepage.text &&
                                touched.content &&
                                touched.content.homepage &&
                                touched.content.homepage.text && (
                                  <div className="mt-1 danger">
                                    {errors.content.homepage.text}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="form-group row has-danger">
                            <label
                              htmlFor="content.homepage.video"
                              className="col-md-3 label-control"
                            >
                              Vidéo
                            </label>
                            <div className="col-md-9">
                              <input
                                type="text"
                                id="content.homepage.video"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.content.homepage.video}
                              />
                              {errors.content &&
                                errors.content.homepage &&
                                errors.content.homepage &&
                                errors.content.homepage.video &&
                                touched.content &&
                                touched.content.homepage &&
                                touched.content.homepage &&
                                touched.content.homepage.video && (
                                  <div className="mt-1 danger">
                                    {errors.content.video}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="form-group row has-danger">
                            <label
                              htmlFor="content.homepage.buttons.button_1.isActive"
                              className="col-md-3 label-control"
                            >
                              Bouton n°1
                            </label>
                            <div className="col-md-9">
                              <label className="mr-4">
                                Avec
                                <input
                                  type="radio"
                                  id="content.homepage.buttons.button_1.isActive"
                                  checked={
                                    values.content.homepage?.buttons.button_1
                                      .isActive === "1"
                                  }
                                  className="ml-1"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={"1"}
                                />
                              </label>
                              <label>
                                Sans
                                <input
                                  type="radio"
                                  id="content.homepage.buttons.button_1.isActive"
                                  checked={
                                    values.content.homepage?.buttons.button_1
                                      .isActive === "0"
                                  }
                                  className="ml-1"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={"0"}
                                />
                              </label>
                              {errors.content &&
                                errors.content.homepage &&
                                errors.content.homepage?.buttons &&
                                errors.content.homepage?.buttons.button_1 &&
                                errors.content.homepage?.buttons.button_1
                                  .isActive &&
                                touched.content &&
                                touched.content.homepage &&
                                touched.content.homepage?.buttons &&
                                touched.content.homepage?.buttons.button_1 &&
                                touched.content.homepage?.buttons.button_1
                                  .isActive && (
                                  <div className="mt-1 danger">
                                    {
                                      errors.content.homepage?.buttons.button_1
                                        .isActive
                                    }
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="form-group row has-danger">
                            <label
                              htmlFor="content.homepage.buttons.button_1.label"
                              className="col-md-3 label-control"
                            >
                              Nom du bouton n°1
                            </label>
                            <div className="col-md-9">
                              <input
                                type="text"
                                id="content.homepage.buttons.button_1.label"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={
                                  values.content.homepage?.buttons.button_1
                                    .label
                                }
                              />
                              {errors.content &&
                                errors.content.homepage &&
                                errors.content.homepage?.buttons &&
                                errors.content.homepage?.buttons.button_1 &&
                                errors.content.homepage?.buttons.button_1
                                  .label &&
                                touched.content &&
                                touched.content.homepage &&
                                touched.content.homepage?.buttons &&
                                touched.content.homepage?.buttons.button_1 &&
                                touched.content.homepage?.buttons.button_1
                                  .label && (
                                  <div className="mt-1 danger">
                                    {
                                      errors.content.homepage?.buttons.button_1
                                        .label
                                    }
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="form-group row has-danger">
                            <label
                              htmlFor="content.homepage.buttons.button_2.isActive"
                              className="col-md-3 label-control"
                            >
                              Bouton n°2
                            </label>
                            <div className="col-md-9">
                              <label className="mr-4">
                                Avec
                                <input
                                  type="radio"
                                  id="content.homepage.buttons.button_2.isActive"
                                  checked={
                                    values.content.homepage?.buttons.button_2
                                      .isActive === "1"
                                  }
                                  className="ml-1"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={"1"}
                                />
                              </label>
                              <label>
                                Sans
                                <input
                                  type="radio"
                                  id="content.homepage.buttons.button_2.isActive"
                                  checked={
                                    values.content.homepage?.buttons.button_2
                                      .isActive === "0"
                                  }
                                  className="ml-1"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={"0"}
                                />
                              </label>
                              {errors.content &&
                                errors.content.homepage &&
                                errors.content.homepage?.buttons &&
                                errors.content.homepage?.buttons.button_2 &&
                                errors.content.homepage?.buttons.button_2
                                  .isActive &&
                                touched.content &&
                                touched.content.homepage &&
                                touched.content.homepage?.buttons &&
                                touched.content.homepage?.buttons.button_2 &&
                                touched.content.homepage?.buttons.button_2
                                  .isActive && (
                                  <div className="mt-1 danger">
                                    {
                                      errors.content.homepage.buttons.button_2
                                        .isActive
                                    }
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="form-group row has-danger">
                            <label
                              htmlFor="content.homepage.buttons.button_2.label"
                              className="col-md-3 label-control"
                            >
                              Nom du bouton n°2
                            </label>
                            <div className="col-md-9">
                              <input
                                type="text"
                                id="content.homepage.buttons.button_2.label"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={
                                  values.content.homepage?.buttons.button_2
                                    .label
                                }
                              />
                              {errors.content &&
                                errors.content.homepage &&
                                errors.content.homepage?.buttons &&
                                errors.content.homepage?.buttons.button_2 &&
                                errors.content.homepage?.buttons.button_2
                                  .label &&
                                touched.content &&
                                touched.content.homepage &&
                                touched.content.homepage?.buttons &&
                                touched.content.homepage?.buttons.button_2 &&
                                touched.content.homepage?.buttons.button_2
                                  .label && (
                                  <div className="mt-1 danger">
                                    {
                                      errors.content.homepage?.buttons.button_2
                                        .label
                                    }
                                  </div>
                                )}
                            </div>
                          </div>
                          <h4 className="form-section">
                            Bloc familles de produit
                          </h4>
                          <div className="form-group row has-danger">
                            <label
                              htmlFor="content.productFamilies.title"
                              className="col-md-3 label-control"
                            >
                              Titre
                            </label>
                            <div className="col-md-9">
                              <input
                                type="text"
                                id="content.productFamilies.title"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.content.productFamilies.title}
                              />
                              {errors.content &&
                                errors.content.productFamilies &&
                                errors.content.productFamilies.title &&
                                touched.content &&
                                touched.content.productFamilies &&
                                touched.content.productFamilies.title && (
                                  <div className="mt-1 danger">
                                    {errors.content.productFamilies.title}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="form-group row has-danger">
                            <label
                              htmlFor="content.productFamilies.text"
                              className="col-md-3 label-control"
                            >
                              Texte
                            </label>
                            <div className="col-md-9">
                              <Editor
                                editorState={familyProductState}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={(e) =>
                                  handleTextChange(
                                    e,
                                    setFamilyProductState,
                                    setFieldValue,
                                    "content.productFamilies.text"
                                  )
                                }
                              />
                              {errors.content &&
                                errors.content.productFamilies &&
                                errors.content.productFamilies.text &&
                                touched.content &&
                                touched.content.productFamilies &&
                                touched.content.productFamilies.text && (
                                  <div className="mt-1 danger">
                                    {errors.content.productFamilies.text}
                                  </div>
                                )}
                            </div>
                          </div>
                          <h4 className="form-section">Page "A propos"</h4>
                          <div className="form-group row has-danger">
                            <label
                              htmlFor="documentation.about.title"
                              className="col-md-3 label-control"
                            >
                              Titre
                            </label>
                            <div className="col-md-9">
                              <input
                                type="text"
                                id="documentation.about.title"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.documentation.about.title}
                              />
                              {errors.documentation &&
                                errors.documentation.about &&
                                errors.documentation.about.title &&
                                touched.documentation &&
                                touched.documentation.about &&
                                touched.documentation.about.title && (
                                  <div className="mt-1 danger">
                                    {errors.documentation.about.title}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="form-group row has-danger">
                            <label
                              htmlFor="documentation.about.description"
                              className="col-md-3 label-control"
                            >
                              Texte
                            </label>
                            <div className="col-md-9">
                              <Editor
                                editorState={documentationAboutState}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={(e) =>
                                  handleTextChange(
                                    e,
                                    setDocumentationAboutState,
                                    setFieldValue,
                                    "documentation.about.description"
                                  )
                                }
                              />
                              {errors.documentation &&
                                errors.documentation.about &&
                                errors.documentation.about.description &&
                                touched.documentation &&
                                touched.documentation.about &&
                                touched.documentation.about.description && (
                                  <div className="mt-1 danger">
                                    {errors.documentation.about.description}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="form-group row has-danger">
                            <label
                              htmlFor="aboutImage"
                              className="col-md-3 label-control"
                            >
                              Image
                            </label>
                            <div className="col-md-9">
                              <input
                                type="file"
                                id="aboutImage"
                                className="form-control"
                                onChange={(e) =>
                                  onChangeFile(e, setFieldValue, "aboutImage")
                                }
                                onBlur={handleBlur}
                              />
                              {configurator &&
                                configurator.documentation &&
                                configurator.documentation.about &&
                                configurator.documentation.about.image !==
                                "" && (
                                  <div className={{maxWidth: 500}}>
                                    Image actuelle :{" "}
                                    <img
                                      className="img-fluid"
                                      alt=""
                                      src={
                                        configurator.documentation.about.image
                                      }
                                    />
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="form-group row has-danger">
                            <label
                              htmlFor="documentation.about.menu.isActive"
                              className="col-md-3 label-control"
                            >
                              Accès à la page dans le menu de navigation
                            </label>
                            <div className="col-md-9">
                              <label className="mr-4">
                                Avec
                                <input
                                  type="radio"
                                  id="documentation.about.menu.isActive"
                                  checked={
                                    values.documentation.about.menu
                                      ?.isActive === "1"
                                  }
                                  className="ml-1"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={"1"}
                                />
                              </label>
                              <label>
                                Sans
                                <input
                                  type="radio"
                                  id="documentation.about.menu.isActive"
                                  checked={
                                    values.documentation.about.menu
                                      ?.isActive === "0"
                                  }
                                  className="ml-1"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={"0"}
                                />
                              </label>
                              {errors.documentation &&
                                errors.documentation.about &&
                                errors.documentation.about.menu &&
                                errors.documentation.about.menu.isActive &&
                                touched.documentation &&
                                touched.documentation.about &&
                                touched.documentation.about.menu &&
                                touched.documentation.about.menu.isActive && (
                                  <div className="mt-1 danger">
                                    {errors.documentation.about.menu.isActive}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="form-group row has-danger">
                            <label
                              htmlFor="documentation.about.menu.label"
                              className="col-md-3 label-control"
                            >
                              Nom de la page dans le menu de navigation et Titre de la page
                            </label>
                            <div className="col-md-9">
                              <input
                                type="text"
                                id="documentation.about.menu.label"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.documentation.about.menu?.label}
                              />
                              {errors.documentation &&
                                errors.documentation.about &&
                                errors.documentation.about.menu &&
                                errors.documentation.about.menu.label &&
                                touched.documentation &&
                                touched.documentation.about &&
                                touched.documentation.about.menu &&
                                touched.documentation.about.menu.label && (
                                  <div className="mt-1 danger">
                                    {errors.documentation.about.menu.label}
                                  </div>
                                )}
                            </div>
                          </div>
                          <h4 className="form-section">Page "FDES mères"</h4>
                          <div className="form-group row has-danger">
                            <label
                              htmlFor="documentation.fdesMeres.title"
                              className="col-md-3 label-control"
                            >
                              Titre
                            </label>
                            <div className="col-md-9">
                              <input
                                type="text"
                                id="documentation.fdesMeres.title"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.documentation.fdesMeres.title}
                              />
                              {errors.documentation &&
                                errors.documentation.fdesMeres &&
                                errors.documentation.fdesMeres.title &&
                                touched.documentation &&
                                touched.documentation.fdesMeres &&
                                touched.documentation.fdesMeres.title && (
                                  <div className="mt-1 danger">
                                    {errors.documentation.fdesMeres.title}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="form-group row has-danger">
                            <label
                              htmlFor="documentation.fdesMeres.description"
                              className="col-md-3 label-control"
                            >
                              Texte
                            </label>
                            <div className="col-md-9">
                              <Editor
                                editorState={documentationFdesMereState}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={(e) =>
                                  handleTextChange(
                                    e,
                                    setDocumentationFdesMereState,
                                    setFieldValue,
                                    "documentation.fdesMeres.description"
                                  )
                                }
                              />
                              {errors.documentation &&
                                errors.documentation.fdesMeres &&
                                errors.documentation.fdesMeres.description &&
                                touched.documentation &&
                                touched.documentation.fdesMeres &&
                                touched.documentation.fdesMeres.description && (
                                  <div className="mt-1 danger">
                                    {errors.documentation.fdesMeres.description}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="form-group row has-danger">
                            <label
                              htmlFor="documentation.fdesMeres.menu.isActive"
                              className="col-md-3 label-control"
                            >
                              Accès à la page dans le menu de navigation
                            </label>
                            <div className="col-md-9">
                              <label className="mr-4">
                                Avec
                                <input
                                  type="radio"
                                  id="documentation.fdesMeres.menu.isActive"
                                  checked={
                                    values.documentation.fdesMeres.menu
                                      ?.isActive === "1"
                                  }
                                  className="ml-1"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={"1"}
                                />
                              </label>
                              <label>
                                Sans
                                <input
                                  type="radio"
                                  id="documentation.fdesMeres.menu.isActive"
                                  checked={
                                    values.documentation.fdesMeres.menu
                                      ?.isActive === "0"
                                  }
                                  className="ml-1"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={"0"}
                                />
                              </label>
                              {errors.documentation &&
                                errors.documentation.fdesMeres &&
                                errors.documentation.fdesMeres.menu &&
                                errors.documentation.fdesMeres.menu.isActive &&
                                touched.documentation &&
                                touched.documentation.fdesMeres &&
                                touched.documentation.fdesMeres.menu &&
                                touched.documentation.fdesMeres.menu
                                  .isActive && (
                                  <div className="mt-1 danger">
                                    {
                                      errors.documentation.fdesMeres.menu
                                        .isActive
                                    }
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="form-group row has-danger">
                            <label
                              htmlFor="documentation.fdesMeres.menu.label"
                              className="col-md-3 label-control"
                            >
                              Nom de la page dans le menu de navigation et Titre de la page
                            </label>
                            <div className="col-md-9">
                              <input
                                type="text"
                                id="documentation.fdesMeres.menu.label"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={
                                  values.documentation.fdesMeres.menu?.label
                                }
                              />
                              {errors.documentation &&
                                errors.documentation.fdesMeres &&
                                errors.documentation.fdesMeres.menu &&
                                errors.documentation.fdesMeres.menu.label &&
                                touched.documentation &&
                                touched.documentation.fdesMeres &&
                                touched.documentation.fdesMeres.menu &&
                                touched.documentation.fdesMeres.menu.label && (
                                  <div className="mt-1 danger">
                                    {errors.documentation.fdesMeres.menu.label}
                                  </div>
                                )}
                            </div>
                          </div>
                          {values.documentation.fdesMeres &&
                            values.documentation.fdesMeres.elements &&
                            values.documentation.fdesMeres.elements.length >
                            0 && (
                              <table>
                                <thead>
                                  <tr>
                                    <th>Titre</th>
                                    <th>Description</th>
                                    <th>Fichier</th>
                                    <th>Image</th>
                                    <th>Ordre</th>
                                    <th>Actions</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {values.documentation.fdesMeres.elements.map(
                                    (element, index) => (
                                      <tr key={index}>
                                        <td className="pb-2">
                                          <input
                                            type="text"
                                            id={`documentation.fdesMeres.elements[${index}].title`}
                                            className="form-control"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={
                                              values.documentation.fdesMeres
                                                .elements[index].title
                                            }
                                          />
                                        </td>
                                        <td className="pb-2">
                                          <input
                                            type="text"
                                            id={`documentation.fdesMeres.elements[${index}].description`}
                                            className="form-control"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={
                                              values.documentation.fdesMeres
                                                .elements[index].description
                                            }
                                          />
                                        </td>
                                        <td className="pt-2">
                                          <input
                                            type="file"
                                            id={`documentation.fdesMeres.elements[${index}].fileToSend`}
                                            className="form-control"
                                            onChange={(e) =>
                                              onChangeFile(
                                                e,
                                                setFieldValue,
                                                `documentation.fdesMeres.elements[${index}].fileToSend`
                                              )
                                            }
                                            onBlur={handleBlur}
                                          />

                                          {configurator &&
                                            configurator.documentation &&
                                            configurator.documentation
                                              .fdesMeres &&
                                            configurator.documentation.fdesMeres
                                              .elements &&
                                            configurator.documentation.fdesMeres
                                              .elements[index] &&
                                            configurator.documentation.fdesMeres
                                              .elements[index].file !== "" && (
                                              <div>
                                                Fichier actuel :{" "}
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href={
                                                    configurator.documentation
                                                      .fdesMeres.elements[index]
                                                      .file
                                                  }
                                                >
                                                  {
                                                    configurator.documentation
                                                      .fdesMeres.elements[index]
                                                      .file
                                                  }
                                                </a>
                                              </div>
                                            )}
                                        </td>
                                        <td className="pb-2">
                                          <input
                                            type="file"
                                            id={`documentation.fdesMeres.elements[${index}].imageToSend`}
                                            className="form-control"
                                            onChange={(e) =>
                                              onChangeFile(
                                                e,
                                                setFieldValue,
                                                `documentation.fdesMeres.elements[${index}].imageToSend`
                                              )
                                            }
                                            onBlur={handleBlur}
                                          />

                                          {configurator &&
                                            configurator.documentation &&
                                            configurator.documentation
                                              .fdesMeres &&
                                            configurator.documentation.fdesMeres
                                              .elements &&
                                            configurator.documentation.fdesMeres
                                              .elements[index] &&
                                            configurator.documentation.fdesMeres
                                              .elements[index].image !== "" && (
                                              <div className="max-image">
                                                Image actuelle :{" "}
                                                <img
                                                  className="img-fluid"
                                                  alt=""
                                                  src={
                                                    configurator.documentation
                                                      .fdesMeres.elements[index]
                                                      .image
                                                  }
                                                />
                                              </div>
                                            )}
                                        </td>
                                        <td className="pb-2">
                                          <input
                                            className="ml-2 col-md-4 px-0"
                                            type="number"
                                            id={`documentation.fdesMeres.elements[${index}].order`}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={
                                              values.documentation.fdesMeres
                                                .elements[index].order
                                            }
                                          />
                                        </td>
                                        <td className="pb-2">
                                          <button
                                            className="btn btn-danger"
                                            type="button"
                                            onClick={() =>
                                              handleDeleteElement(
                                                values,
                                                setFieldValue,
                                                index
                                              )
                                            }
                                          >
                                            <i className="feather icon-trash-2" />
                                          </button>
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            )}
                          <div className="form-group row has-danger mt-2 justify-content-end">
                            <button
                              className="btn btn-primary"
                              type="button"
                              onClick={() =>
                                handleAddElement(values, setFieldValue)
                              }
                            >
                              Ajouter un élément
                            </button>
                          </div>
                          <h4 className="form-section">Page "Tutoriels"</h4>
                          <div className="form-group row has-danger">
                            <label
                              htmlFor="documentation.videoTutorial.title"
                              className="col-md-3 label-control"
                            >
                              Titre
                            </label>
                            <div className="col-md-9">
                              <input
                                type="text"
                                id="documentation.videoTutorial.title"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.documentation.videoTutorial.title}
                              />
                              {errors.documentation &&
                                errors.documentation.videoTutorial &&
                                errors.documentation.videoTutorial.title &&
                                touched.documentation &&
                                touched.documentation.videoTutorial &&
                                touched.documentation.videoTutorial.title && (
                                  <div className="mt-1 danger">
                                    {errors.documentation.videoTutorial.title}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="form-group row has-danger">
                            <label
                              htmlFor="documentation.videoTutorial.description"
                              className="col-md-3 label-control"
                            >
                              Texte
                            </label>
                            <div className="col-md-9">
                              <Editor
                                editorState={documentationVideoTutorialState}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={(e) =>
                                  handleTextChange(
                                    e,
                                    setDocumentationVideoTutorialState,
                                    setFieldValue,
                                    "documentation.videoTutorial.description"
                                  )
                                }
                              />
                              {errors.documentation &&
                                errors.documentation.videoTutorial &&
                                errors.documentation.videoTutorial
                                  .description &&
                                touched.documentation &&
                                touched.documentation.videoTutorial &&
                                touched.documentation.videoTutorial
                                  .description && (
                                  <div className="mt-1 danger">
                                    {
                                      errors.documentation.videoTutorial
                                        .description
                                    }
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="form-group row has-danger">
                            <label
                              htmlFor="content.documentation.videoTutorial.video"
                              className="col-md-3 label-control"
                            >
                              Vidéo
                            </label>
                            <div className="col-md-9">
                              <input
                                type="text"
                                id="documentation.videoTutorial.video"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.documentation.videoTutorial.video}
                              />
                              {errors.documentation &&
                                errors.documentation.videoTutorial &&
                                errors.documentation.videoTutorial.video &&
                                touched.documentation &&
                                touched.documentation.video &&
                                touched.documentation.videoTutorial.video && (
                                  <div className="mt-1 danger">
                                    {errors.documentation.videoTutorial.video}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="form-group row has-danger">
                            <label
                              htmlFor="documentation.videoTutorial.menu.isActive"
                              className="col-md-3 label-control"
                            >
                              Accès à la page dans le menu de navigation
                            </label>
                            <div className="col-md-9">
                              <label className="mr-4">
                                Avec
                                <input
                                  type="radio"
                                  id="documentation.videoTutorial.menu.isActive"
                                  checked={
                                    values.documentation.videoTutorial.menu
                                      ?.isActive === "1"
                                  }
                                  className="ml-1"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={"1"}
                                />
                              </label>
                              <label>
                                Sans
                                <input
                                  type="radio"
                                  id="documentation.videoTutorial.menu.isActive"
                                  checked={
                                    values.documentation.videoTutorial.menu
                                      ?.isActive === "0"
                                  }
                                  className="ml-1"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={"0"}
                                />
                              </label>
                              {errors.documentation &&
                                errors.documentation.videoTutorial &&
                                errors.documentation.videoTutorial.menu &&
                                errors.documentation.videoTutorial.menu
                                  .isActive &&
                                touched.documentation &&
                                touched.documentation.videoTutorial &&
                                touched.documentation.videoTutorial.menu &&
                                touched.documentation.videoTutorial.menu
                                  .isActive && (
                                  <div className="mt-1 danger">
                                    {
                                      errors.documentation.videoTutorial.menu
                                        .isActive
                                    }
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="form-group row has-danger">
                            <label
                              htmlFor="documentation.videoTutorial.menu.label"
                              className="col-md-3 label-control"
                            >
                              Nom de la page dans le menu de navigation et Titre de la page
                            </label>
                            <div className="col-md-9">
                              <input
                                type="text"
                                id="documentation.videoTutorial.menu.label"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={
                                  values.documentation.videoTutorial.menu?.label
                                }
                              />
                              {errors.documentation &&
                                errors.documentation.videoTutorial &&
                                errors.documentation.videoTutorial.menu &&
                                errors.documentation.videoTutorial.menu.label &&
                                touched.documentation &&
                                touched.documentation.videoTutorial &&
                                touched.documentation.videoTutorial.menu &&
                                touched.documentation.videoTutorial.menu
                                  .label && (
                                  <div className="mt-1 danger">
                                    {
                                      errors.documentation.videoTutorial.menu
                                        .label
                                    }
                                  </div>
                                )}
                            </div>
                          </div>
                          <h4 className="form-section">Page "Guides"</h4>
                          <div className="form-group row has-danger">
                            <label
                              htmlFor="documentation.guide.title"
                              className="col-md-3 label-control"
                            >
                              Titre
                            </label>
                            <div className="col-md-9">
                              <input
                                type="text"
                                id="documentation.guide.title"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.documentation.guide.title}
                              />
                              {errors.documentation &&
                                errors.documentation.guide &&
                                errors.documentation.guide.title &&
                                touched.documentation &&
                                touched.documentation.guide &&
                                touched.documentation.guide.title && (
                                  <div className="mt-1 danger">
                                    {errors.documentation.guide.title}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="form-group row has-danger">
                            <label
                              htmlFor="documentation.guide.description"
                              className="col-md-3 label-control"
                            >
                              Texte
                            </label>
                            <div className="col-md-9">
                              <Editor
                                editorState={documentationGuideState}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={(e) =>
                                  handleTextChange(
                                    e,
                                    setDocumentationGuideState,
                                    setFieldValue,
                                    "documentation.guide.description"
                                  )
                                }
                              />
                              {errors.documentation &&
                                errors.documentation.videoTutorial &&
                                errors.documentation.videoTutorial
                                  .description &&
                                touched.documentation &&
                                touched.documentation.videoTutorial &&
                                touched.documentation.videoTutorial
                                  .description && (
                                  <div className="mt-1 danger">
                                    {
                                      errors.documentation.videoTutorial
                                        .description
                                    }
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="form-group row has-danger">
                            <label
                              htmlFor="guideFile"
                              className="col-md-3 label-control"
                            >
                              Fichier
                            </label>
                            <div className="col-md-9">
                              <input
                                type="file"
                                id="guideFile"
                                className="form-control"
                                onChange={(e) =>
                                  onChangeFile(e, setFieldValue, "guideFile")
                                }
                                onBlur={handleBlur}
                              />

                              {configurator &&
                                configurator.documentation &&
                                configurator.documentation.guide &&
                                configurator.documentation.guide.file !==
                                "" && (
                                  <div>
                                    Fichier actuel :{" "}
                                    <a
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      href={
                                        configurator.documentation.guide.file
                                      }
                                    >
                                      {configurator.documentation.guide.file}
                                    </a>
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="form-group row has-danger">
                            <label
                              htmlFor="documentation.guide.label"
                              className="col-md-3 label-control"
                            >
                              Libellé guide
                            </label>
                            <div className="col-md-9">
                              <input
                                type="text"
                                id="documentation.guide.label"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.documentation.guide.label}
                              />
                              {errors.documentation &&
                                errors.documentation.guide &&
                                errors.documentation.guide.title &&
                                touched.documentation &&
                                touched.documentation.guide &&
                                touched.documentation.guide.label && (
                                  <div className="mt-1 danger">
                                    {errors.documentation.guide.label}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="form-group row has-danger">
                            <label
                              htmlFor="documentation.guide.menu.isActive"
                              className="col-md-3 label-control"
                            >
                              Accès à la page dans le menu de navigation
                            </label>
                            <div className="col-md-9">
                              <label className="mr-4">
                                Avec
                                <input
                                  type="radio"
                                  id="documentation.guide.menu.isActive"
                                  checked={
                                    values.documentation.guide.menu
                                      ?.isActive === "1"
                                  }
                                  className="ml-1"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={"1"}
                                />
                              </label>
                              <label>
                                Sans
                                <input
                                  type="radio"
                                  id="documentation.guide.menu.isActive"
                                  checked={
                                    values.documentation.guide.menu
                                      ?.isActive === "0"
                                  }
                                  className="ml-1"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={"0"}
                                />
                              </label>
                              {errors.documentation &&
                                errors.documentation.guide &&
                                errors.documentation.guide.menu &&
                                errors.documentation.guide.menu.isActive &&
                                touched.documentation &&
                                touched.documentation.guide &&
                                touched.documentation.guide.menu &&
                                touched.documentation.guide.menu.isActive && (
                                  <div className="mt-1 danger">
                                    {errors.documentation.guide.menu.isActive}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="form-group row has-danger">
                            <label
                              htmlFor="documentation.guide.menu.label"
                              className="col-md-3 label-control"
                            >
                              Nom de la page dans le menu de navigation et Titre de la page
                            </label>
                            <div className="col-md-9">
                              <input
                                type="text"
                                id="documentation.guide.menu.label"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.documentation.guide.menu?.label}
                              />
                              {errors.documentation &&
                                errors.documentation.guide &&
                                errors.documentation.guide.menu &&
                                errors.documentation.guide.menu.label &&
                                touched.documentation &&
                                touched.documentation.guide &&
                                touched.documentation.guide.menu &&
                                touched.documentation.guide.menu.label && (
                                  <div className="mt-1 danger">
                                    {errors.documentation.guide.menu.label}
                                  </div>
                                )}
                            </div>
                          </div>
                          <h4 className="form-section">Page "Méthodologie"</h4>
                          <div className="form-group row has-danger">
                            <label
                              htmlFor="documentation.methodology.title"
                              className="col-md-3 label-control"
                            >
                              Titre
                            </label>
                            <div className="col-md-9">
                              <input
                                type="text"
                                id="documentation.methodology.title"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.documentation.methodology.title}
                              />
                              {errors.documentation &&
                                errors.documentation.methodology &&
                                errors.documentation.methodology.title &&
                                touched.documentation &&
                                touched.documentation.methodology &&
                                touched.documentation.methodology.title && (
                                  <div className="mt-1 danger">
                                    {errors.documentation.methodology.title}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="form-group row has-danger">
                            <label
                              htmlFor="documentation.methodology.description"
                              className="col-md-3 label-control"
                            >
                              Texte
                            </label>
                            <div className="col-md-9">
                              <Editor
                                editorState={documentationMethodologyState}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={(e) =>
                                  handleTextChange(
                                    e,
                                    setDocumentationMethodologyState,
                                    setFieldValue,
                                    "documentation.methodology.description"
                                  )
                                }
                              />
                              {errors.documentation &&
                                errors.documentation.methodology &&
                                errors.documentation.methodology.description &&
                                touched.documentation &&
                                touched.documentation.methodology &&
                                touched.documentation.methodology
                                  .description && (
                                  <div className="mt-1 danger">
                                    {
                                      errors.documentation.methodology
                                        .description
                                    }
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="form-group row has-danger">
                            <label
                              htmlFor="methodologyFile"
                              className="col-md-3 label-control"
                            >
                              Fichier note générale
                            </label>
                            <div className="col-md-9">
                              <input
                                type="file"
                                id="methodologyFile"
                                className="form-control"
                                onChange={(e) =>
                                  onChangeFile(
                                    e,
                                    setFieldValue,
                                    "methodologyFile"
                                  )
                                }
                                onBlur={handleBlur}
                              />
                              {configurator &&
                                configurator.documentation &&
                                configurator.documentation.methodology &&
                                configurator.documentation.methodology.file !==
                                "" && (
                                  <div>
                                    Fichier actuel :{" "}
                                    <a
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      href={
                                        configurator.documentation.methodology
                                          .file
                                      }
                                    >
                                      {
                                        configurator.documentation.methodology
                                          .file
                                      }
                                    </a>
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="form-group row has-danger">
                            <label
                              htmlFor="documentation.methodology.label"
                              className="col-md-3 label-control"
                            >
                              Libellé note générale
                            </label>
                            <div className="col-md-9">
                              <input
                                type="text"
                                id="documentation.methodology.label"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.documentation.methodology.label}
                              />
                              {errors.documentation &&
                                errors.documentation.methodology &&
                                errors.documentation.methodology.label &&
                                touched.documentation &&
                                touched.documentation.methodology &&
                                touched.documentation.methodology.label && (
                                  <div className="mt-1 danger">
                                    {errors.documentation.methodology.label}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="form-group row has-danger">
                            <label
                              htmlFor="documentation.methodology.menu.isActive"
                              className="col-md-3 label-control"
                            >
                              Accès à la page dans le menu de navigation
                            </label>
                            <div className="col-md-9">
                              <label className="mr-4">
                                Avec
                                <input
                                  type="radio"
                                  id="documentation.methodology.menu.isActive"
                                  checked={
                                    values.documentation.methodology.menu
                                      ?.isActive === "1"
                                  }
                                  className="ml-1"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={"1"}
                                />
                              </label>
                              <label>
                                Sans
                                <input
                                  type="radio"
                                  id="documentation.methodology.menu.isActive"
                                  checked={
                                    values.documentation.methodology.menu
                                      ?.isActive === "0"
                                  }
                                  className="ml-1"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={"0"}
                                />
                              </label>
                              {errors.documentation &&
                                errors.documentation.methodology &&
                                errors.documentation.methodology.menu &&
                                errors.documentation.methodology.menu
                                  .isActive &&
                                touched.documentation &&
                                touched.documentation.methodology &&
                                touched.documentation.methodology.menu &&
                                touched.documentation.methodology.menu
                                  .isActive && (
                                  <div className="mt-1 danger">
                                    {
                                      errors.documentation.methodology.menu
                                        .isActive
                                    }
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="form-group row has-danger">
                            <label
                              htmlFor="documentation.methodology.menu.label"
                              className="col-md-3 label-control"
                            >
                              Nom de la page dans le menu de navigation et Titre de la page
                            </label>
                            <div className="col-md-9">
                              <input
                                type="text"
                                id="documentation.methodology.menu.label"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={
                                  values.documentation.methodology.menu?.label
                                }
                              />
                              {errors.documentation &&
                                errors.documentation.methodology &&
                                errors.documentation.methodology.menu &&
                                errors.documentation.methodology.menu.label &&
                                touched.documentation &&
                                touched.documentation.methodology &&
                                touched.documentation.methodology.menu &&
                                touched.documentation.methodology.menu
                                  .label && (
                                  <div className="mt-1 danger">
                                    {
                                      errors.documentation.methodology.menu
                                        .label
                                    }
                                  </div>
                                )}
                            </div>
                          </div>
                          <h4 className="form-section">
                            Page assistance : FAQ
                          </h4>
                          <div className="form-group row has-danger">
                            <label
                              htmlFor="documentation.faq.allowOnMenu"
                              className="col-md-3 label-control"
                            >
                              Accès à la page dans le menu de navigation
                            </label>
                            <div className="col-md-9">
                              <label className="mr-4">
                                Avec
                                <input
                                  type="radio"
                                  id="documentation.faq.allowOnMenu"
                                  checked={
                                    values.documentation.faq.allowOnMenu === "1"
                                  }
                                  className="ml-1"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={"1"}
                                />
                              </label>
                              <label>
                                Sans
                                <input
                                  type="radio"
                                  id="documentation.faq.allowOnMenu"
                                  checked={
                                    values.documentation.faq.allowOnMenu === "0"
                                  }
                                  className="ml-1"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={"0"}
                                />
                              </label>
                              {errors?.documentation?.faq?.allowOnMenu &&
                                touched?.documentation?.faq?.allowOnMenu && (
                                  <div className="mt-1 danger">
                                    {
                                      errors.documentation.faq.allowOnMenu
                                    }
                                  </div>
                                )}
                            </div>
                          </div>
                          {values.documentation.faq &&
                            values.documentation.faq.elements &&
                            values.documentation.faq.elements.length > 0 && (
                              <>
                                {values.documentation.faq.elements.map(
                                  (element, index) => (
                                    <div className="row p-0" key={index}>
                                      <div className="col-12">
                                        <div className="form-group row has-danger">
                                          <label
                                            htmlFor={`documentation.faq.elements[${index}].title`}
                                            className="col-md-3 label-control"
                                          >
                                            Titre
                                          </label>
                                          <div className="col-md-9">
                                            <input
                                              type="text"
                                              id={`documentation.faq.elements[${index}].title`}
                                              className="form-control"
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              value={
                                                values.documentation?.faq
                                                  ?.elements[index]?.title
                                              }
                                            />
                                            {errors?.documentation &&
                                              errors?.documentation?.faq &&
                                              errors?.documentation?.faq
                                                ?.elements &&
                                              touched?.documentation &&
                                              touched?.documentation?.faq &&
                                              touched?.documentation?.faq
                                                ?.elements && (
                                                <div className="mt-1 danger">
                                                  {
                                                    errors?.documentation?.faq
                                                      ?.elements[index]?.title
                                                  }
                                                </div>
                                              )}
                                          </div>
                                        </div>
                                        <div className="form-group row has-danger">
                                          <label
                                            htmlFor={`documentation.faq.elements[${index}].text`}
                                            className="col-md-3 label-control"
                                          >
                                            Texte
                                          </label>
                                          <div className="col-md-9">
                                            <Editor
                                              editorState={
                                                documentationFaqState[index]
                                              }
                                              toolbarClassName="toolbarClassName"
                                              wrapperClassName="wrapperClassName"
                                              editorClassName="editorClassName"
                                              onEditorStateChange={(e) =>
                                                handleTextChangeForFaq(
                                                  e,
                                                  setDocumentationFaqState,
                                                  setFieldValue,
                                                  `documentation.faq.elements[${index}].text`,
                                                  index
                                                )
                                              }
                                            />
                                            {errors?.documentation &&
                                              errors?.documentation?.faq &&
                                              errors?.documentation?.faq
                                                ?.elements &&
                                              touched?.documentation &&
                                              touched?.documentation?.faq &&
                                              touched.documentation?.faq
                                                ?.elements && (
                                                <div className="mt-1 danger">
                                                  {
                                                    errors?.documentation?.faq
                                                      ?.elements[index]?.text
                                                  }
                                                </div>
                                              )}
                                          </div>
                                        </div>
                                        <div className="form-group row has-danger">
                                          <label
                                            htmlFor={`documentation.faq.elements[${index}].order`}
                                            className="col-md-3 label-control"
                                          >
                                            Ordre
                                          </label>
                                          <div className="col-md-8" style={{paddingRight: '1.2rem'}}>
                                            <input
                                              type="number"
                                              className="form-control"
                                              id={`documentation.faq.elements[${index}].order`}
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              value={
                                                values.documentation.faq
                                                  .elements[index].order
                                              }
                                            />
                                          </div>
                                          {errors?.documentation &&
                                            errors?.documentation?.faq &&
                                            errors?.documentation?.faq
                                              ?.elements &&
                                            touched?.documentation &&
                                            touched?.documentation?.faq &&
                                            touched.documentation?.faq
                                              ?.elements && (
                                              <div className="mt-1 danger">
                                                {
                                                  errors?.documentation?.faq
                                                    ?.elements[index]?.order
                                                }
                                              </div>
                                            )}
                                          <div className="col-1">
                                            <div className="form-group row has-danger border-bottom-0">
                                              <button
                                                className="btn btn-danger"
                                                type="button"
                                                onClick={() =>
                                                  handleDeleteElementForFaq(
                                                    values,
                                                    setFieldValue,
                                                    index
                                                  )
                                                }
                                              >
                                                <i className="feather icon-trash-2" />
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )}
                              </>
                            )}
                          <div className="form-group row has-danger py-2 justify-content-end">
                            <button
                              className="btn btn-primary"
                              type="button"
                              onClick={() =>
                                handleAddElementForFaq(values, setFieldValue)
                              }
                            >
                              Ajouter un élément
                            </button>
                          </div>
                        </div>
                      </div>
                      <div
                        id="familyProduct"
                        className={`form-body tab-pane`}
                        aria-labelledby="familyProduct-tab"
                        role="tabpanel"
                      >
                        <div className="form-body mt-2 col-md-8">
                          {familyProducts &&
                            familyProducts.length > 0 &&
                            familyProducts.map((familyProduct) =>
                              displayFamilyProduct(
                                familyProduct,
                                0,
                                setFieldValue,
                                values
                              )
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="form-actions right mt-0">
                      <Link to="/configurators">
                        <button type="reset" className="btn btn-warning mr-1">
                          <i className="feather icon-x" /> Annuler
                        </button>
                      </Link>
                      <button type="submit" className="btn btn-primary">
                        <i className="fa fa-check-square-o" /> Enregistrer
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          );
        }}
      </Formik>
    </>
  );
};

export default ConfiguratorForm;
